import React from "react";
import {Route, Switch, useRouteMatch} from "react-router-dom"
import InfluencerList from './InfluencerList';
import InfluencerDetail from './InfluencerDetail';

function Influencer() {
  let match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/:influencerId`}>
        <InfluencerDetail />
      </Route>
      <Route path={match.path}>
        <InfluencerList />
      </Route>
    </Switch>

  );
}

export default Influencer;
