import React, { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'

function DataTableSidebar(props) {
  const opened = props.isOpened;
  const edit = props.edit;

  const fieldList = initFieldList();
  const [fields, setFields] = useState(fieldList);
  const [files, setFiles] = useState([]);

  React.useEffect(() => {
    setFields(() => fieldList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  function handleFieldChange(event, field) {
    const newFields = fields;
    newFields[field] = event.target.value;
    setFields({...newFields});
  }

  function handleFileChange(event, field) {
    const newFiles = files;
    newFiles[field] = event.target.files[0];
    setFiles({...newFiles});
  }

  function initFieldList() {
    const fieldList = {};

    if (props.edit) {
      props.config.fields.map((fieldGroups) => {
        fieldGroups.fields.map((field) => {
          fieldList[field.name] = props.editData[field.name];
          return null;
        });
        return null;
      });
    } else {
      props.config.fields.map((fieldGroups) => {
        fieldGroups.fields.map((field) => {
          fieldList[field.name] = (field.default) ? field.default : '';
          return null;
        });
        return null;
      });
    }
    return fieldList;
  }

  function closeHandler(event) {
    if (props.closeHandler) props.closeHandler(event);
    setFields(() => initFieldList());
  }

  function submitHandler(event) {
    event.preventDefault();
    if (props.saveHandler) props.saveHandler(event, fields, files);
    closeHandler(event);
  }

  return (
    <Transition.Root show={opened} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 overflow-hidden" onClose={closeHandler}>
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0" />
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-2xl">
                <form className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll" onSubmit={submitHandler}>
                  <div className="flex-1">
                    {/* Header */}
                    <div className="px-4 py-6 bg-gray-50 sm:px-6">
                      <div className="flex items-start justify-between space-x-3">
                        <div className="space-y-1">
                          <Dialog.Title className="text-lg font-medium text-gray-900">
                            {(!props.edit) ?
                              'Neu ' + props.config.title
                              :
                              'Bearbeite ' + props.config.titleEdit
                            }
                          </Dialog.Title>
                          <p className="text-sm text-gray-500">
                            {(!props.edit) ?
                              props.config.description
                              :
                              props.config.descriptionEdit
                            }
                          </p>
                        </div>
                        <div className="h-7 flex items-center">
                          <button
                            type="button"
                            className="text-gray-400 hover:text-gray-500"
                            onClick={closeHandler}
                          >
                            <span className="sr-only">Close panel</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>

                    {props.config.fields.map((fieldsGroup, index) => (
                      <div
                        key={'fieldgroup-add-new-' + index}
                      >
                        {(fieldsGroup.type === 'group') && (
                          <div className="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                            {(fieldsGroup.caption) && (
                              <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-2">
                                <h2>{fieldsGroup.caption}</h2>
                              </div>
                            )}
                            {fieldsGroup.fields.map((field) => (
                              <div
                                key={'field-' + field.name}
                              >
                                {(field.type === 'text') && (
                                  <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                                    <div>
                                      <label
                                        htmlFor={'field-' + field.name}
                                        className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                      >
                                        {field.label}
                                      </label>
                                    </div>
                                    <div className="sm:col-span-2">
                                      <input
                                        type="text"
                                        name={'field-' + field.name}
                                        id={'field-' + field.name}
                                        className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                                        value={fields[field.name]}
                                        onChange={(event) => {
                                          handleFieldChange(event, field.name)
                                        }}
                                      />
                                    </div>
                                  </div>
                                )}
                                {(field.type === 'datetime') && (
                                  <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                                    <div>
                                      <label
                                        htmlFor={'field-' + field.name}
                                        className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                      >
                                        {field.label}
                                      </label>
                                    </div>
                                    <div className="sm:col-span-2">
                                      <input
                                        type="datetime-local"
                                        name={'field-' + field.name}
                                        id={'field-' + field.name}
                                        className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                                        value={fields[field.name]}
                                        onChange={(event) => {
                                          handleFieldChange(event, field.name)
                                        }}
                                      />
                                    </div>
                                  </div>
                                )}
                                {(field.type === 'file') && (
                                  <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                                    <div>
                                      <label
                                        htmlFor={'field-' + field.name}
                                        className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                      >
                                        {field.label}
                                      </label>
                                    </div>
                                    <div className="sm:col-span-2">
                                      <input
                                        type="file"
                                        name={'field-' + field.name}
                                        id={'field-' + field.name}
                                        className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                                        onChange={(event) => {
                                          handleFileChange(event, field.name)
                                        }}
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>

                  {/* Action buttons */}
                  <div className="flex-shrink-0 px-4 border-t border-gray-200 py-5 sm:px-6">
                    <div className="space-x-3 flex justify-end">
                      <button
                        type="button"
                        className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        onClick={closeHandler}
                      >
                        {(props.config.cancelBtnLabel) ? props.config.cancelBtnLabel : 'Abbrechen'}
                      </button>
                      <button
                        type="submit"
                        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        {(!props.edit) ?
                          (props.config.saveBtnLabel) ? props.config.saveBtnLabel : 'Erstellen'
                          :
                          (props.config.editBtnLabel) ? props.config.editBtnLabel : 'Aktualisieren'
                        }
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default DataTableSidebar;
