import React from 'react';
import Table from '../../components/Table/table'
import Pagination from '../../components/Pagination/pagination'
import {PlusCircleIcon} from "@heroicons/react/solid";
import DataTableSidebar from './DataTableSidebar';
import DataTableSearch from "./DataTableSearch";
import DataTableFilters from "./DataTableFilters";
import Error from '../../components/Helper/error';

function DataTable(props) {
  const config = props.config;
  const url = props.config.url;

  const [data, setData] = React.useState([]);
  const [count, setCount] = React.useState(0);
  const [offset, setOffset] = React.useState(0);
  const [limit] = React.useState((props.limit) ? props.limit : 10);
  const [loading, setLoading] = React.useState(true);
  const [addNewIsOpened, setAddNewIsOpened] = React.useState(false);
  const [editIsOpened, setEditIsOpened] = React.useState(false);
  const [editData, setEditData] = React.useState(false);
  const [addingError, setAddingError] = React.useState(false);
  const [deleteMessage, setDeleteMessage] = React.useState({ show: false, okHandler: () => {}});
  const [search, setSearch] = React.useState({search: undefined, filters: []});

  React.useEffect(() => {
    loadData();
    if (props.edit) {
      setEditIsOpened(() => true);
      console.log(props.editData)
      setEditData(() => props.editData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, limit, offset]);

  function loadData() {
    setLoading(() => true);
    let paginationUrl = url;
    if (paginationUrl.indexOf('?') > -1) {
      paginationUrl = paginationUrl + '&';
    } else {
      paginationUrl = paginationUrl + '?';
    }
    paginationUrl = paginationUrl + 'limit=' + limit + '&offset=' + offset;
    if (search && search.search) {
      paginationUrl = paginationUrl + '&search=' + search.search;
    }
    if (search && search.filters) {
      Object.entries(search.filters).map(([key, value])=> {
        let filtersettings = {};
        config.search.filters.map((filter, index) => {
          if (filter.key === key) {
            filtersettings = filter;
          }
        });
        if (filtersettings.format === 'range') {
          if (value && value[0] !== null) {
            paginationUrl = paginationUrl + '&min_' + key + '=' + value[0];
          }
          if (value && value[1] !== null) {
            paginationUrl = paginationUrl + '&max_' + key + '=' + value[1];
          }
        } else if (filtersettings.format === 'agesplit') {
          const str = [];
          Object.entries(value.options).map(([option, on])=> {
            if (on) {
              str.push(option);
            }
          });
          str.push(value.min / 100.0)
          paginationUrl = paginationUrl + '&' + key + '=' + str;
        } else if (filtersettings.format === 'gendersplit') {
          if (value.minMale !== 0 || value.minFemale !== 0) {
            if (value.minMale !== 0) {
              paginationUrl = paginationUrl + '&' + key + '__gte=' + (value.minMale / 100.0);
            } else {
              paginationUrl = paginationUrl + '&' + key + '__lte=' + (1.0 - (value.minFemale / 100.0));
            }
          }
        } else {
          if (value && value !== '') {
            paginationUrl = paginationUrl + '&' + key + '=' + value;
          }
        }
      });
    }
    window.api.get(paginationUrl)
      .then(function(response) {
        if (response && response.data && response.data.results) {
          setData(() => response.data.results);
          setCount(() => response.data.count);
        }
        setLoading(() => false);
      })
      .catch(function (error) {
        setLoading(() => false);
      });
  }

  function paginationHandler(params) {
    setOffset(() => params.offset);
  }

  function addNewCloseHandler() {
    setEditIsOpened(() => false);
    setAddNewIsOpened(() => false);
  }

  function addNewSaveHandler(event, values) {
    event.preventDefault();
    setAddNewIsOpened(() => false);
    setLoading(() => true);

    if (editData) {
      window.api.patch(url + editData.pk + '/', values)
        .then(function(response) {
          setOffset(() => 0);
          loadData();
        })
        .catch(function (error) {
          setLoading(() => false);
          setAddingError(() => true);
        });
    } else {
      window.api.post(url, values)
        .then(function(response) {
          setOffset(() => 0);
          loadData();
        })
        .catch(function (error) {
          setLoading(() => false);
          setAddingError(() => true);
        });
    }
  }

  function deleteHandler(data) {
    setDeleteMessage({
      show: true,
      okHandler: () => {
        let deleteUrl = url;
        if (deleteUrl.indexOf('?') > -1) {
          deleteUrl = deleteUrl.split('?')[0];
        }
        window.api.delete(deleteUrl + data.pk + '/')
          .then(function(response) {
            setOffset(() => 0);
            loadData();
          })
          .catch(function (error) {
            setLoading(() => false);
            setAddingError(() => true)
          });
      }
    });
  }

  function handleSearchChange(query) {
    let newSearch = search;
    newSearch.search = query;
    setSearch(() => newSearch);
    loadData();
  }

  function handleFiltersChange(filters) {
    let newSearch = search;
    newSearch.filters = filters;
    setSearch(() => newSearch);
    loadData();
  }

  return (
    <>
      <div className="max-w-8xl mx-auto px-4 sm:px-6 lg:px-8">
        {(props.config.fields && !props.config.noAdd) && (
          <div className="float-right">
            <button
              type="button"
              className="inline-flex float-left mr-1 items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 bg-green-600 hover:bg-green-700"
              onClick={()=>{
                setAddNewIsOpened(() => true);
              }}
            >
              <PlusCircleIcon className="h-4 w-4" aria-hidden="true" />
            </button>
          </div>
        )}
        <h2 className="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate mb-3">{props.config.title}</h2>
        {(props.config.search) && (
          <>
            <DataTableSearch config={config} handleSearchChange={handleSearchChange} />
            <DataTableFilters config={config} handleFiltersChange={handleFiltersChange} />
          </>
        )}
        <Table config={config} data={data} key="DataTable" loading={loading} deleteHandler={deleteHandler} />
        <Pagination count={count} limit={limit} offset={offset} clickHandler={paginationHandler} loading={loading}  />
        {(props.config.fields && props.config.fields.length > 0) && (
          <>
            <DataTableSidebar
              config={props.config}
              isOpened={addNewIsOpened || editIsOpened}
              edit={editIsOpened}
              editData={editData}
              closeHandler={addNewCloseHandler}
              saveHandler={addNewSaveHandler}
            />
            <Error
              open={deleteMessage.show}
              type="delete"
              title={"Wirklich löschen?"}
              message={"Soll dieser Eintrag wirklich gelöscht werden?"}
              buttons={[
                {
                  'caption': 'Ja, löschen',
                  'classNames': 'bg-red-500 hover:bg-red-600 !text-white border-red-500',
                  'onCloseHandler': () => {
                    if (deleteMessage.okHandler) deleteMessage.okHandler();
                    setDeleteMessage({
                      show: false,
                      okHandler: () => {},
                    });
                  }
                },
                {
                  'caption': 'Nein',
                  'classNames': '',
                  'onCloseHandler': () => {
                    setDeleteMessage({
                      show: false,
                      okHandler: () => {},
                    });
                  }
                }
              ]}
              onCloseHandler={() => {
                setDeleteMessage({
                  show: false,
                  okHandler: () => {},
                });
              }}
            />
            <Error
              open={addingError}
              type="error"
              title={"Fehler beim speichern"}
              message={"Es ist ein Fehler beim speichern aufgetreten. Bitte versuchen Sie es später erneut."}
              buttons={[
                /*{
                  'caption': 'Dismiss',
                  'classNames': 'bg-red-500 hover:bg-red-600 text-red-900 border-red-500',
                  'onCloseHandler': () => {
                    setAddingError(() => false);
                  }
                },*/
                {
                  'caption': 'Ok',
                  'classNames': '',
                  'onCloseHandler': () => {
                    setAddingError(() => false);
                  }
                }
              ]}
              onCloseHandler={() => {
                setAddingError(() => false);
              }}
            />
          </>
        )}
      </div>
    </>
  );
}

export default DataTable;
