import {Link, NavLink, Route, Switch} from "react-router-dom";
import React from "react";
import InfluencerDetailTikTok from "./InfluencerDetailTikTok";
import InfluencerDetailOverview from "./InfluencerDetailOverview";
import DataTable from "./DataTable";
import {TrashIcon} from "@heroicons/react/solid";


function InfluencerDetailInstagramPosts(props) {
  const data = props.data;
  const config = {
    title: '',
    description: 'Fügen Sie einen neuen Influencer hinzu.',
    url: '/api/posts/?influencer=' + data.pk,
    noAdd: true,
    columns: [
      {
        title: 'Post Bild',
        column: 'image',
        type: 'dataset',
        options: {
          format: 'portrait',
        },
      },
      {
        title: 'Posted',
        column: 'posted',
        type: 'dataset',
        options: {
          format: 'datetime',
        },
      },
      {
        title: 'Kommentar',
        column: 'comment',
        type: 'dataset',
        options: {
          format: 'longtext',
        },
      },
      {
        title: 'Werte',
        type: 'group',
        column: 'name',
        options: {
          labelAsPrefix: true,
        },
        elements: [
          {
            title: 'ER',
            column: 'engagement_rate',
            type: 'dataset',
          },
          {
            title: 'Likes',
            column: 'likes',
            type: 'dataset',
          },
          {
            title: 'Kommentare',
            column: 'comments',
            type: 'dataset',
          },
        ]
      },
      {
        title: 'Aktionen',
        type: 'actions',
      },
    ],
    actions: [
      {
        action: {
          classNames: "bg-red-600 hover:bg-red-700",
          type: 'delete',
          content: (
            <TrashIcon className="h-4 w-4" aria-hidden="true" />
          )
        },
      }
    ]
  };

  return (
    <>
       <br />
       <DataTable config={config} />
    </>
  );
}

export default InfluencerDetailInstagramPosts;
