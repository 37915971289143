import React, { useState } from 'react';
import PropTypes from 'prop-types';
import I18n from '../../helpers/I18n'

import { LockClosedIcon } from '@heroicons/react/solid'

async function loginUser(credentials) {
  credentials.grant_type = 'password';
  credentials.client_id = 'PNtZ9IjFRTYQ1d28De8F43iBie09JYqVVI2DlLat';
  credentials.client_secret = '9cZ3m5k0eI5moZzIFTgCGbEb7iLwUkyMm0FmEQUzMqkaiosOedwDWppyIFXUoXrM3KfMwbpZt38vNlGN1BdRbHeiiyVsm1q1UIsORoixtybpUxXvbKR6BZo8DzDhIp6U';

  return window.api.post('/user/auth/token/', JSON.stringify(credentials))
    .then(function(response) {
      if (response && response.data) {
        return response.data;
      }
      throw Error();
    })
    .catch(function (error) {
      if (error && !error.response) {
        alert('Server not reachabble. Try again later.');
        return;
      } else if (error.response.status === 400) {
        alert('Anmeldedaten falsch');
      } else {
        alert('Unbekannter fehler');
      }
      throw Error(error);
    });
}

function Login({ setToken }) {
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();

  const handleSubmit = async e => {
    e.preventDefault();
    loginUser({
      username,
      password
    })
      .then((response) => {
        setToken(response);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <img
            className="mx-auto h-12 w-auto"
            src="/gfx/logo.png"
            alt="inSoTo Logo"
          />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">{I18n.t('LOGIN.HEADLINE')}</h2>
        </div>
        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
          <input type="hidden" name="remember" defaultValue="true" />
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="email-address" className="sr-only">
                {I18n.t('LOGIN.USERNAME')}
              </label>
              <input
                autoComplete="username"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
                placeholder={I18n.t('LOGIN.USERNAME')}
                type="text" onChange={e => setUserName(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">
                {I18n.t('LOGIN.PASSWORD')}
              </label>
              <input
                type="password"
                autoComplete="current-password"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
                placeholder={I18n.t('LOGIN.PASSWORD')}
                onChange={e => setPassword(e.target.value)}
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
            >
              <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                <LockClosedIcon className="h-5 w-5 text-gray-500 group-hover:text-gray-400" aria-hidden="true" />
              </span>
              {I18n.t('LOGIN.BUTTON_LOGIN')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login

Login.propTypes = {
  setToken: PropTypes.func.isRequired
};
