import React from 'react';
import Row from './row';

function Table(props) {
   return (
    <div className={
      'flex flex-col' +
      ((props.loading) ? ' pbd-blur' : ' ')
    }
    >
     <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                {props.config.columns.map((column) => (
                  <th
                    key={'table-column-' + column.column}
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    style={{
                      maxWidth: (column.options && column.options.maxWidth) ? column.options.maxWidth: '100%',
                    }}
                  >
                    {column.title}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {props.data.map((data) => (
                <Row key={'table-row-' + data.pk} data={data} columns={props.config.columns} actions={props.config.actions} deleteHandler={props.deleteHandler}></Row>
              ))}
            </tbody>
          </table>
        </div>
      </div>
     </div>
    </div>
  );
}

export default Table;
