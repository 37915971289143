import DataTable from "./DataTable";
import DataTableSidebar from "./DataTableSidebar";
import TagEditor from "./TagEditor";
import {Dialog, Transition} from "@headlessui/react";
import React, {Fragment} from "react";
import {CheckIcon, EyeIcon, NewspaperIcon, PencilIcon, TrashIcon, UploadIcon, TagIcon} from "@heroicons/react/solid";
import {useHistory} from "react-router-dom";

function InfluencerList() {
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  let history = useHistory();
  let refDataTable = undefined;

  const [uploadInstagramInsight, setUploadInstagramInsight] = React.useState(false);
  const [editTags, setEditTags] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [editableTags, setEditableTags] = React.useState([]);
  const [modalUpload, setModalUpload] = React.useState(false);
  const [editInfluencer, setEditInfluencer] = React.useState(undefined);
  const [uploadResult, setUploadResult] = React.useState(undefined);
  const [previewImage, setPreviewImage] = React.useState('');

  const config = {
    title: 'Influencer',
    titleEdit: 'Influencer',
    description: 'Fügen Sie einen neuen Influencer hinzu.',
    descriptionEdit: 'Bearbeiten Sie einen Influencer.',
    url: '/api/influencer/',
    fields: [
      {
        type: 'group',
        caption: undefined,
        fields: [
          {
            name: 'first_name',
            type: 'text',
            label: 'Vorname',
            required: true,
            default: '',
          },
          {
            name: 'last_name',
            type: 'text',
            label: 'Nachname',
            required: true,
            default: '',
          },
        ]
      },
      {
        type: 'group',
        caption: 'Networks',
        fields: [
          {
            name: 'instagram',
            type: 'text',
            label: 'Instagram Account',
            help: 'e.g. cristiano'
          },
          {
            name: 'tiktok',
            type: 'text',
            label: 'TikTok Account',
            help: 'e.g. @_._cristiano'
          }
        ]
      },
    ],
    search: {
      freeText: true,
      filters: [
        {
          key: 'gender',
          title: 'Geschlecht',
          format: 'select',
          values: [
            {
              value: '',
              description: 'alle',
              icon: '-'
            },
            {
              value: 'm',
              description: 'männlich',
              icon: '♂'
            },
            {
              value: 'w',
              description: 'weiblich',
              icon: '♀'
            },
            {
              value: 'd',
              description: 'divers',
              icon: '⚧',
            }
          ]
        },
        {
          key: 'instagram_follower',
          title: 'Follower',
          format: 'range',
        },
        {
          key: 'instagram_age_split',
          title: 'Follower Alter',
          format: 'agesplit',
          values: [
            {
              value: 'group1',
              description: '13-17',
              state: false,
            },
            {
              value: 'group2',
              description: '18-24',
              state: false,
            },
            {
              value: 'group3',
              description: '25-34',
              state: false,
            },
            {
              value: 'group4',
              description: '35-44',
            },
            {
              value: 'group5',
              description: '45-54',
            },
            {
              value: 'group6',
              description: '55-64',
            },
            {
              value: 'group7',
              description: '65+',
            },
          ]
        },
        {
          key: 'instagram_gender_split',
          title: 'Follower Geschlecht',
          format: 'gendersplit',
        },
      ]
    },
    columns: [
      {
        title: 'Instagram Bild',
        column: 'instagram_image',
        type: 'dataset',
        options: {
          format: 'avatar',
        },
      },
      {
        title: 'Name',
        type: 'group',
        column: 'name',
        options: {
          labelAsPrefix: false,
        },
        elements: [
          {
            title: 'Nachname',
            column: 'last_name',
            type: 'dataset',
          },
          {
            title: 'Vorname',
            column: 'first_name',
            type: 'dataset',
          },
        ]
      },
      {
        title: 'Instagram',
        type: 'group',
        column: 'instagram',
        options: {
          labelAsPrefix: true,
          hideIfEmpty: 'instagram'
        },
        elements: [
          {
            title: 'Profil',
            column: 'instagram',
            type: 'dataset',
            options: {
              externalLink: {
                'prefix': 'https://instagram.com/',
                'postfix': '/'
              }
            }
          },
          {
            title: 'Follower',
            column: 'instagram_follower',
            type: 'dataset',
            options: {
              format: 'number',
            }
          },
          {
            title: 'ER',
            column: 'instagram_engagement_rate_posts',
            type: 'dataset',
            options: {
              format: 'percent',
            }
          },
        ]
      },
      {
        title: 'Tags',
        type: 'dataset',
        column: 'tags',
        options: {
          format: 'tags',
          maxWidth: 200,
          whitespace: 'wrap',
        }
      },
      {
        title: 'Aktionen',
        type: 'actions',
      },
    ],
    actions: [
      {
        action: {
          classNames: "bg-green-500 hover:bg-green-600",
          onClick: (params) => {
            history.push('/influencer/' + params.pk)
          },
          content: (
            <EyeIcon className="h-4 w-3" aria-hidden="true" />
          )
        },
      },
      {
        action: {
          classNames: "bg-yellow-600 hover:bg-yellow-700",
          onClick: (params) => {
            setEditInfluencer(() => params);
            setPreviewImage(() => undefined);
            setUploadResult(() => undefined);
            setUploadInstagramInsight(() => true);
          },
          content: (
            <UploadIcon className="h-4 w-3" aria-hidden="true" />
          )
        },
      },
      {
        action: {
          classNames: "bg-pink-600 hover:bg-pink-700",
          onClick: (params) => {
            setEditInfluencer(() => params);
            setEditTags(() => true);
          },
          content: (
            <TagIcon className="h-4 w-3" aria-hidden="true" />
          )
        },
      },
      {
        action: {
          classNames: "bg-indigo-600 hover:bg-indigo-700",
          onClick: (params) => {
            setEditInfluencer(() => params);
            setEdit(() => true);
          },
          content: (
            <PencilIcon className="h-4 w-3" aria-hidden="true" />
          )
        },
      },
      {
        action: {
          classNames: "bg-red-600 hover:bg-red-700",
          type: 'delete',
          content: (
            <TrashIcon className="h-4 w-3" aria-hidden="true" />
          )
        },
      }
    ]
  };

  return (
    <>
      <DataTable ref={(ref) => {refDataTable = ref}} config={config} edit={edit} editData={editInfluencer} limit={20} />
      <TagEditor
        isOpened={editTags}
        tags={(editInfluencer) ? editInfluencer.tags : []}
        closeHandler={() => {
          setEditInfluencer(() => undefined);
          setEditableTags(() => []);
          setEditTags(() => false);
        }}
        removeTagHandler={(tag) => {
          const url = config.url + editInfluencer.pk + '/tags/' + tag.pk + '/';
          window.api.delete(url)
            .then(function() {
              refDataTable.loadData();
            })
            .catch(function (error) {

            });
        }}
        addTagHandler={(tag) => {
          const url = config.url + editInfluencer.pk + '/tags/' + tag.pk + '/';
          window.api.post(url)
            .then(function() {
              refDataTable.loadData();
            })
            .catch(function (error) {

            });
        }}
      >

      </TagEditor>
      <DataTableSidebar
        config={{
          title: 'Upload InSight',
          fields: [
            {
              type: 'group',
              caption: undefined,
              fields: [
                {
                  name: 'date',
                  type: 'datetime',
                  label: 'Bericht vom',
                  default: new Date().toLocaleDateString(),
                  required: true,
                },
              ]
            },
            {
              type: 'group',
              caption: undefined,
              fields: [
                {
                  name: 'file',
                  type: 'file',
                  label: 'InSight Bericht',
                  help: '(JPG oder PNG)'
                },
              ]
            },
          ],
          saveBtnLabel: 'Upload',
        }}
        isOpened={uploadInstagramInsight}
        closeHandler={() => {
          setUploadInstagramInsight(() => false);
        }}
        saveHandler={(event, values, files) => {
          event.preventDefault();
          setUploadInstagramInsight(() => false);
          setModalUpload(() => true)

          let formData = undefined;
          if (files && files.file) {
            formData = new FormData();

            // Update the formData object
            formData.append(
              "file",
              files.file,
              files.file.name
            );
          }
          const reader = new FileReader();
          reader.readAsBinaryString(files.file);
          reader.onload = function() {
            setPreviewImage("data:image/png;base64, " + btoa(reader.result))
          };

          window.api.post(config.url + editInfluencer.pk + '/upload/', formData)
            .then(function(response) {
              setUploadResult(() => JSON.stringify(response.data, '\n', 2))
            })
            .catch(function (error) {
              console.log(error)
            });

          if (formData) {
            console.log(formData)
          }
        }}
      />
      <Transition.Root show={modalUpload} as={Fragment}>
        <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={setModalUpload}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6 md:max-w-md lg:max-w-lg">
                <div>
                  <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                    {(!uploadResult) ? (
                      <UploadIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                    ) : (
                      <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                    )}
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                      {(!uploadResult) ? (
                        <>Bericht wird verarbeitet...</>
                      ) : (
                        <>Verarbeitung abgeschlossen.</>
                      )}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        {(!uploadResult) ? (
                          <>
                            Die Datei wird hochgeladen und verarbeitet. Das Ergebniss der Verarbeitung wird hier angezeigt. Sie können auch die Verarbeitung später betrachten und vorerst weiter arbeiten, klicken Sie hierzu auf "Im Hintegrund"
                          </>
                        ) : (
                          <></>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <div className="grid gap-4 grid-cols-2">
                    <div className="relative">
                      <img
                        src={previewImage}
                        className="pbd-blur pbd-blur-animation-background"
                        alt="Uploaded Preview"
                      />
                      <div
                        className={classNames(
                          'pbd-blur-animation',
                          (uploadResult) ? 'pbd-blur-animation-finished' : '',
                        )}
                        style={{
                          backgroundImage: `url("${previewImage}")`,
                        }}>

                      </div>
                    </div>
                    <div>
                      <pre className="text-xs break-all">
                        {(uploadResult) ? uploadResult : 'warten auf das Ergebniss...'}
                      </pre>
                    </div>

                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                    onClick={() => setModalUpload(false)}
                  >
                    {(uploadResult) ? 'schließen' : 'Im Hintergrund'}
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

export default InfluencerList;
