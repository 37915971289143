import {Link, NavLink, Route, Switch, useRouteMatch, Redirect} from "react-router-dom";
import React from "react";
import InfluencerDetailInstagramPosts from "./InfluencerDetailInstagramPosts";
import InfluencerDetailInstagramStories from "./InfluencerDetailInstagramStories";

const tabs = [
  { name: 'Stories', href: '#', current: true },
  { name: 'Posts', href: '#', current: false },
]


function InfluencerDetailInstagram(props) {
  let match = useRouteMatch();

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  const data = props.data;

  return (
    <>
      <div className="flex mt-6 px-4">
        <div className="flex flex-1">
          <nav className="flex flex-1 z-0 rounded-lg shadow flex" aria-label="Tabs">
            <NavLink
              to={`/influencer/${data.pk}/instagram/stories`}
              className={classNames(
                'rounded-l-lg',
                'group relative flex-1 overflow-hidden border-white border-b-2 bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10'
              )}
              activeClassName={'text-gray-900 border-pink-500 border-b-2'}
              exact={true}
            >
              Stories
            </NavLink>
            <NavLink
              to={`/influencer/${data.pk}/instagram/posts`}
              className={classNames(
                'rounded-r-lg',
                'group relative flex-1 overflow-hidden border-white border-b-2 bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10'
              )}
              activeClassName={'text-gray-900 border-pink-500 border-b-2'}
              exact={true}
            >
              Posts
            </NavLink>
          </nav>
        </div>
      </div>
      <Switch>

        <Route path={`${match.path}/stories`}>
          <InfluencerDetailInstagramStories data={data} />
        </Route>
        <Route path={`${match.path}/posts`}>
          <InfluencerDetailInstagramPosts data={data} />
        </Route>
        <Route path={`${match.path}`}>
          <InfluencerDetailInstagramStories data={data} />
        </Route>
      </Switch>
    </>
  );
}

export default InfluencerDetailInstagram;
