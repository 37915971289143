import React from 'react';
import ReactDOM from 'react-dom';
import { StoreProvider } from 'easy-peasy';
import store from './store';
import './index.css';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import App from './App';
import reportWebVitals from './reportWebVitals';
import api from "./helpers/api";
window.api = api;

Sentry.init({
  dsn: "https://04ee32af2c4c4be09021de8c019dacf7@sentry.iwsdev.de/2",
  release: "inSoTo-dashboard@" + process.env.npm_package_version,
  integrations: [new Integrations.BrowserTracing()],
  attachStacktrace: true,
  debug: false,
  enabled: true, //window.location.host.indexOf('localhost') === -1 && window.location.host.indexOf('0.0.0.0') === -1 && window.location.host.indexOf('127.0.0.1') === -1,
  tracesSampleRate: 1.0,
});
Sentry.setContext("user", {
  id: undefined,
  username: '',
});

ReactDOM.render(
  <React.StrictMode>
    <StoreProvider store={store}>
      <App />
    </StoreProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
