import React, { Fragment, useState, useEffect } from 'react'
import * as _ from "lodash";
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import {PlusCircleIcon} from "@heroicons/react/solid";
import Tag from "../../components/tag";
import { CompactPicker as ColorPicker } from 'react-color';
import { ArrowsExpandIcon, PencilIcon, TrashIcon } from '@heroicons/react/solid'

function TagEditor(props) {
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  const opened = props.isOpened;

  const [loading, setLoading] = React.useState(true);
  const [allTags, setAllTags] = React.useState([]);
  const [availableTags, setAvailableTags] = React.useState([]);
  const [usedTags, setUsedTags] = React.useState(props.tags);
  const [mode, setMode] = React.useState('move');

  const [addNewTag, setAddNewTag] = React.useState(false);
  const [newTagTag, setNewTagTag] = React.useState('');
  const [newTagColorText, setNewTagColorText] = React.useState('#FFFFFF');
  const [newTagColorBackground, setNewTagColorBackground] = React.useState('#000000');

  React.useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    setUsedTags(() => props.tags)
    calcAvailableTags();
  }, [props]);

  function calcAvailableTags() {
    let availableTags = [];
    allTags.map((tag, index) => {
      let found = false;
      usedTags.map((tag2) => {
        if (tag.pk === tag2.pk) {
          found = true;
          return false;
        }
      });
      if (!found) availableTags.push(tag);
    });
    setAvailableTags(() => availableTags);
  }

  useEffect(() => {
    calcAvailableTags();
  }, [usedTags]);

  function loadData() {
    setLoading(() => true);
    window.api.get('/api/tags/')
      .then(function(response) {
        if (response && response.data) {
          setAllTags(() => response.data);
        } else {
          setAllTags(() => []);
        }
        setLoading(() => false);
      })
      .catch(function (error) {
        setLoading(() => false);
      });
  }

  function saveNewTag(newTag) {
    setLoading(() => true);
    window.api.post('/api/tags/', newTag)
      .then(function(response) {
        setLoading(() => false);
        let avTags = availableTags;
        avTags.push(newTag)
        setAvailableTags(() => avTags);
        loadData();
      })
      .catch(function (error) {
        if (error.response && error.response.status === 400) {
          alert('Already exists')
        }
        setLoading(() => false);
      });
  }


  function closeHandler(event) {
    if (props.closeHandler) props.closeHandler(event);

  }

  function submitHandler(event) {
    event.preventDefault();
    closeHandler(event);
  }

  function addTag(tag) {
    let newTags = usedTags;
    newTags.push(tag);
    setUsedTags(() => newTags);
    calcAvailableTags();
    props.addTagHandler(tag);
  }

  function removeTag(tag) {
    let newTags = [];
    usedTags.map((usedTag, index) => {
      if (tag.pk !== usedTag.pk) {
        newTags.push(usedTag);
      }
    });
    setUsedTags(() => newTags);
    calcAvailableTags();
    props.removeTagHandler(tag);
  }

  return (
    <>
      <Transition.Root show={opened} as={Fragment}>
        <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={closeHandler}>
          {(addNewTag) ? (
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

               {/* This element is to trick the browser into centering the modal contents. */}
               <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-md sm:w-full sm:p-6">
                  <div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                        Tag hinzufügen
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Spezifiziere hier deinen neuen Tag
                        </p>
                      </div>
                      <div>
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                          <label htmlFor="last-name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Tag
                          </label>
                          <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <input
                              maxLength={24}
                              type="text"
                              name="newTagTag"
                              id="newTagTag"
                              className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                              onChange={(event)=> {
                                setNewTagTag(() => event.target.value);
                              }}
                            />
                          </div>
                        </div>
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                          <label htmlFor="last-name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Textfarbe
                          </label>
                          <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <input
                              value={newTagColorText}
                              readOnly={true}
                              type="text"
                              name="newTagColorText"
                              id="newTagColorText"
                              className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                            />
                            <ColorPicker
                              color={newTagColorText}
                              onChangeComplete={(color, event) => {
                                setNewTagColorText(() => color.hex);
                              }}
                            />
                          </div>
                        </div>
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                          <label htmlFor="last-name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Hintergrund
                          </label>
                          <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <input
                              value={newTagColorBackground}
                              readOnly={true}
                              type="text"
                              name="newTagColorBackground"
                              id="newTagColorBackground"
                              className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                            />
                            <ColorPicker
                              color={newTagColorBackground}
                              onChangeComplete={(color, event) => {
                                setNewTagColorBackground(() => color.hex);
                              }}
                            />
                          </div>
                        </div>
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                          <label htmlFor="last-name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Vorschau
                          </label>
                          <div className="pt-1">
                            <Tag
                              tag={{
                                tag: newTagTag,
                                colorBackground: newTagColorBackground,
                                colorText: newTagColorText,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                    <button
                      type="button"
                      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                      onClick={() => {
                        let newTag = {
                          tag: newTagTag,
                          colorBackground: newTagColorBackground,
                          colorText: newTagColorText,
                        }
                        saveNewTag(newTag);
                        setAddNewTag(() => false);
                      }}
                    >
                      hinzufügen
                    </button>
                    <button
                      type="button"
                      className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                      onClick={() => {
                        setAddNewTag(() => false);
                      }}
                    >
                      abbrechen
                    </button>
                  </div>
                </div>
              </Transition.Child>
            </div>
          ) : (
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                  <div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                        Tag-Editor
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Alle hier vorgenommenen Änderungen werden direkt gespeichert.
                        </p>
                      </div>
                    </div>
                    <div className="mt-2 grid grid-cols-2 gap-4">
                      <div>
                        <h2 className="text-sm text-gray-500 mb-2">Zugeordnete Tags</h2>
                        <div className="overflow-y-scroll p-2 max-h-80 min-h-22 border-dashed border-2 border-light-blue-500">
                          {_.sortBy(usedTags, 'tag').map((tag, index) => (
                            <div>
                              <Tag key={'usedTag-' + tag.pk} tag={tag} clickHandler={(tag) => { removeTag(tag); }} />
                            </div>
                          ))}
                        </div>
                      </div>
                      <div>
                        <h2 className="text-sm text-gray-500 mb-2">
                          Verfügbare Tags
                        </h2>
                        <div className="overflow-y-scroll p-2 max-h-80 min-h-22 border-dashed border-2 border-light-blue-500">
                          {_.sortBy(availableTags, 'tag').map((tag, index) => (
                            <div>
                              <Tag key={'availableTag-' + tag.pk}  tag={tag} clickHandler={(tag) => { addTag(tag); }} />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex mt-4 justify-center items-center">
                    <button
                      type="button"
                      className={classNames(
                        'mr-3 items-center mt-1 px-2 py-1 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
                        (mode === 'move') ? 'bg-gray-600 hover:bg-gray-700' : 'bg-gray-200 hover:bg-gray-300'
                      )}
                      onClick={()=>{
                        //setAddNewTag(() => true);
                        setMode(() => 'move');
                      }}
                    >
                      <ArrowsExpandIcon className="h-4 w-4 text-green-600 fill-current" aria-hidden="true" />
                    </button>
                    <button
                      type="button"
                      className={classNames(
                        'mr-3 items-center mt-1 px-2 py-1 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
                        (mode === 'edit') ? 'bg-indigo-600 hover:bg-indigo-700' : 'bg-gray-200 hover:bg-gray-300'
                      )}
                      onClick={()=>{
                        //setAddNewTag(() => true);
                        setMode(() => 'edit');
                      }}
                    >
                      <PencilIcon className="h-4 w-4" aria-hidden="true" />
                    </button>
                    <button
                      type="button"
                      className={classNames(
                        'mr-3 items-center mt-1 px-2 py-1 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
                        (mode === 'delete') ? 'bg-red-600 hover:bg-red-700' : 'bg-gray-200 hover:bg-gray-300'
                      )}
                      onClick={()=>{
                        //setAddNewTag(() => true);
                        setMode(() => 'delete');
                      }}
                    >
                      <TrashIcon className="h-4 w-4" aria-hidden="true" />
                    </button>
                    <div className="pr-4">
                      |
                    </div>
                    <button
                      type="button"
                      className="inline-flex bg-green-600 hover:bg-green-700 inline-flex float-left mr-3 items-center mt-1 px-2 py-1 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={()=>{
                        setAddNewTag(() => true);
                      }}
                    >
                      <PlusCircleIcon className="h-4 w-4" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="mt-5 sm:mt-6">
                    <button
                      type="button"
                      className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                      onClick={props.closeHandler}
                    >
                      schließen
                    </button>
                  </div>
                </div>
              </Transition.Child>
            </div>
          )}
        </Dialog>

      </Transition.Root>
    </>
  );
}

export default TagEditor;
