import React, { Fragment, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'

function DataTableFiltersRange(props) {
  const filter = props.filter;

  const [from, setFrom] = useState((filter.from) ? filter.from : null);
  const [to, setTo] = useState((filter.to) ? filter.to : null);

  React.useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (from !== null || to !== null) {
      props.handleFilterChange(filter.key, {'value': [(isNaN(from) ? null : from), (isNaN(to) ? null : to)]});
    }
  }, [from, to]);

  function handleFieldChange(event, which) {
    var val = parseInt(event.target.value);
    if (which === 'min') {
      if (to !== null && !isNaN(to) && val > to) {
        setFrom(() => to);
      } else {
        setFrom(() => val);
      }
    } else if (which === 'max') {
      if (from !== null && !isNaN(from) && val < from) {
        setTo(() => from);
      } else {
        setTo(() => val);
      }
    }
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  return (
    <>
      <div>
        <label htmlFor={filter.key} className="block text-sm font-medium text-gray-700">
          {filter.title}
        </label>
        <div className="grid grid-cols-2 gap-1">
          <div className="mt-1 flex rounded-md shadow-sm">
            <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
              Von
            </span>
            <input
              type="number"
              id={filter.key}
              className="flex-1 min-w-0 block w-full px-2 py-2 rounded-none rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300"
              style={{
                maxWidth: 160,
              }}
              placeholder="0"
              min={0}
              value={from}
              onChange={(event) => {
                handleFieldChange(event, 'min');
              }}
            />
          </div>
          <div className="mt-1 flex rounded-md shadow-sm">
            <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
              Bis
            </span>
            <input
              type="number"
              className="flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300"
              style={{
                maxWidth: 160,
              }}
              placeholder="999999999"
              min={0}
              value={to}
              onChange={(event) => {
                handleFieldChange(event, 'max');
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default DataTableFiltersRange;
