import React, { Fragment, useState } from 'react'
import Slider, { Range } from 'rc-slider'
import 'rc-slider/assets/index.css'
import _ from 'lodash'

function DataTableFiltersAgeSplit(props) {
  const filter = props.filter;

  const [minVal, setMinVal] = useState(30);
  let selectionMap = {};
  filter.values.map((value) => {
    selectionMap[value.value] = (value.state) ? value.state : false
  });
  const [selection, setSelection] = useState(selectionMap);
  const [previousSelection, setPreviousSelection] = useState(selectionMap);

  React.useEffect((a) => {
    if (!_.isEqual(selection, previousSelection)) {
      props.handleFilterChange(filter.key, {'value':{'options':selection,'min':minVal}});
    }
    setPreviousSelection(() => JSON.parse(JSON.stringify(selection)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selection]);

  React.useEffect((a) => {
    props.handleFilterChange(filter.key, {'value':{'options':selection,'min':minVal}});
  }, [minVal]);

  function handleFieldChange(event, which) {
    let newSelectionMap = JSON.parse(JSON.stringify(selection));
    newSelectionMap[which] = event.target.checked;
    setSelection(() => newSelectionMap);
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  return (
    <>
      <div>
        <label htmlFor={filter.key} className="block text-sm font-medium text-gray-700">
          {filter.title}
        </label>
        <div className="grid grid-rows-2 gap-1">
          <div className="flex">
            {filter.values.map((value) => (
              <div className="relative flex items-start mr-2">
                <div className="flex items-center h-5">
                  <input
                    id={(filter.key) + value.value}
                    name={(filter.key) + value.value}
                    type="checkbox"
                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                    defaultChecked={selection[value.value]}
                    onChange={(event) => handleFieldChange(event, value.value)}
                  />
                </div>
                <div className="ml-1 text-sm">
                  <label htmlFor={(filter.key) + value.value} className="font-medium text-gray-700">
                    {value.description}
                    {selection[value.value]}
                  </label>
                </div>
              </div>
            ))}
          </div>
          <div>
            <div className="flex">
              <div className="flex-grow pr-4 pl-2 pt-1">
                <Slider min={0} max={100} defaultValue={minVal}
                        onAfterChange={(val) => {
                          setMinVal(() => val)
                        }}
                        trackStyle={{
                          backgroundColor: 'rgb(5, 150, 105)',
                          height: 10,
                        }}
                        railStyle={{
                          backgroundColor: 'rgb(107, 114, 128)',
                          height: 10,
                        }}
                        handleStyle={{
                          height: 20,
                          width: 20,
                        }}
                />
              </div>
              <div className="flex-none w-10 text-right font-medium text-gray-700">
                {minVal}%
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DataTableFiltersAgeSplit;
