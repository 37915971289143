import React, {useState} from 'react';
import DataTableFiltersSelect from "./DataTableFiltersSelect";
import DataTableFiltersRange from "./DataTableFiltersRange";
import DataTableFiltersAgeSplit from "./DataTableFiltersAgeSplit";
import DataTableFiltersGenderSplit from "./DataTableFiltersGenderSplit";

function DataTableFilters(props) {
  const config = props.config;

  const [filters, setFilters] = useState({});

  React.useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  function handleFilterChange(key, filter) {
    let newFilters = filters;
    newFilters[key] = filter.value;
    setFilters(() => newFilters);
    props.handleFiltersChange(newFilters);
  }

  return (
    <>
      <div className="flex-1 flex">
        <form className="w-full flex md:ml-0 mb-4" action="#" method="GET">
          {(config.search.filters.map((filter, index) => (
            <div className="float-right mr-2">
              {(filter.format === 'select') ? (
                <DataTableFiltersSelect filter={filter} handleFilterChange={handleFilterChange} />
              ) : (
                <>
                   {(filter.format === 'range') ? (
                     <DataTableFiltersRange filter={filter} handleFilterChange={handleFilterChange} />
                   ) : (
                     <>
                       {(filter.format === 'agesplit') ? (
                         <DataTableFiltersAgeSplit filter={filter} handleFilterChange={handleFilterChange} />
                       ) : (
                         <>
                           {(filter.format === 'gendersplit') ? (
                             <DataTableFiltersGenderSplit filter={filter} handleFilterChange={handleFilterChange} />
                           ) : (
                             <>
                              Unknown Filter Type
                             </>
                           )}
                         </>
                       )}
                     </>
                   )}
                </>
              )}
            </div>
          )))}
        </form>
      </div>
    </>
  );
}

export default DataTableFilters;
