import React from 'react';
import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationIcon } from '@heroicons/react/outline';
import I18n from '../../helpers/I18n';
import {useStoreActions} from "easy-peasy";
import * as Sentry from "@sentry/react";

function Ping() {
  const [delay] = React.useState(5000);
  const [tickInterval, setTickInterval] = React.useState();
  const [noConnection, setNoConnection] = React.useState(false);

  const setUser = useStoreActions((actions) => actions.setUser);

  React.useEffect(() => {
    // On Mount // or on render (second param [])
    if (!tickInterval) {
      setTickInterval(() => setInterval(tick, delay));
      tick();
    }

    return () => {
      // Unmount
      clearInterval(tickInterval);
      setTickInterval(() => undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // [] called once, not every update


  function tick() {
    window.api.get('/api/me/')
      .then(function(response) {
        if (response && response.data) {
          Sentry.setContext("user", {
            id: response.data.id,
            username: response.data.username,
          });
          setUser(response.data)
        }
        setNoConnection(() => false);
      })
      .catch(function (error) {
        if (error && error.response && error.response.status === 401) {
          setNoConnection(() => false);
        } else if (error && error.message && error.message.indexOf('Network Error') > -1) {
          setNoConnection(() => true);
        } else {
          setNoConnection(() => false);
        }
      });
  }

  function close() {

  }

  if (noConnection) {
    return (
      <Transition.Root show={true} as={Fragment}>
          <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={null} onClose={close}>
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                  <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                      </div>
                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                          {I18n.t('GENERIC.MODAL_NOCONNECTION_HEADLINE')}
                        </Dialog.Title>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">
                            {I18n.t('GENERIC.MODAL_NOCONNECTION_MESSAGE')}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
    );
  }

  return (
    <></>
  );
}

export default Ping;
