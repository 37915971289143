import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  NavLink,
} from "react-router-dom";

import './App.css';
import { Dashboard, Data, Influencer, Login, Logout, NotFound } from './views';
import Ping from './components/Helper/ping';
import useToken from './components/Helper/useToken';
import ProfileMenu from './components/profileMenu'
import I18n from './helpers/I18n'

import { Fragment, useState } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import {
  BellIcon,
  FilterIcon,
  ClipboardIcon,
  UserCircleIcon,
  MenuAlt1Icon,
  QuestionMarkCircleIcon,
  LogoutIcon,
  AdjustmentsIcon,
  XIcon,
} from '@heroicons/react/outline'
import {
  SearchIcon,
} from '@heroicons/react/solid'

const navigation = [
  //{ name: I18n.t('MENU.DASHBOARD'), to: '/dashboard', icon: ClipboardIcon },
  { name: I18n.t('MENU.INFLUENCER'), to: '/influencer', icon: UserCircleIcon },
  { name: I18n.t('MENU.DATA'), to: '/data', icon: FilterIcon },
]
const secondaryNavigation = [
  //{ name: I18n.t('MENU.SETTINGS'), to: '/settings', icon: AdjustmentsIcon },
  //{ name: I18n.t('MENU.HELP'), to: '/help', icon: QuestionMarkCircleIcon },
  { name: I18n.t('MENU.LOGOUT'), to: '/logout', icon: LogoutIcon },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function App() {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const { token, setToken } = useToken();

  if (!token) {
    return (
      <>
        <Login setToken={setToken} />
      </>
    )
  }

  return (
    <Router>
      <div className="relative h-screen flex overflow-hidden bg-gray-200">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="fixed inset-0 flex z-40 lg:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-gray-100">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex-shrink-0 flex items-center px-4">
                  <img
                    className="h-8 w-auto"
                    src="/gfx/logo.png"
                    alt="inSoTo Logo"
                  />
                </div>
                <nav className="mt-5 flex-shrink-0 h-full divide-y divide-gray-800 overflow-y-auto" aria-label="Sidebar">
                  <div className="px-2 space-y-1">
                    {navigation.map((item) => (
                      <NavLink
                        key={`link-mobile-` + item.name}
                        to={item.to}
                        className={classNames(
                          'text-gray-700 hover:text-gray-700 hover:bg-gray-200',
                          'group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md'
                        )}
                        activeClassName="pbd-main-nav-active-link"
                      >
                        <item.icon className="mr-4 flex-shrink-0 h-6 w-6 text-gray-700" aria-hidden="true" />
                        {item.name}
                      </NavLink>
                    ))}
                  </div>
                  <div className="mt-6 pt-6">
                    <div className="px-2 space-y-1">
                      {secondaryNavigation.map((item) => (
                        <NavLink
                          key={`link-mobile-` + item.name}
                          to={item.to}
                          className={classNames(
                            'text-gray-700 hover:text-gray-700 hover:bg-gray-200',
                            'group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md'
                          )}
                          activeClassName="pbd-main-nav-active-link"
                        >
                        <item.icon className="mr-4 flex-shrink-0 h-6 w-6 text-gray-200" aria-hidden="true" />
                          {item.name}
                      </NavLink>
                      ))}
                    </div>
                  </div>
                </nav>
              </div>
            </Transition.Child>
            <div className="flex-shrink-0 w-14" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:flex lg:flex-shrink-0">
          <div className="flex flex-col w-64">
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <div className="flex flex-col flex-grow bg-gray-100 pb-4 overflow-y-auto">
              <div className="flex items-center flex-shrink-0 px-4 h-16 bg-white pt-2">
                <img
                  className="h-8 w-auto"
                  src="/gfx/logo.png"
                  alt="inSoTo Logo"
                />
              </div>
              <nav className="mt-5 flex-1 flex flex-col divide-y divide-gray-800 overflow-y-auto" aria-label="Sidebar">
                <div className="px-2 space-y-1">
                  {navigation.map((item) => (
                    <NavLink
                      key={`link-` + item.name}
                      to={item.to}
                      className={classNames(
                        'text-gray-700 hover:text-gray-700 hover:bg-gray-200',
                        'group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md'
                      )}
                      activeClassName="pbd-main-nav-active-link"
                      aria-current={item.current ? 'page' : undefined}
                    >
                        <item.icon className="mr-4 flex-shrink-0 h-6 w-6 text-gray-700" aria-hidden="true" />
                      {item.name}
                    </NavLink>
                  ))}
                </div>
                <div className="mt-6 pt-6">
                  <div className="px-2 space-y-1">
                    {secondaryNavigation.map((item) => (
                      <NavLink
                        key={`link-` + item.name}
                        to={item.to}
                        className={classNames(
                          'text-gray-700 hover:text-gray-700 hover:bg-gray-200',
                          'group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md'
                        )}
                        activeClassName="pbd-main-nav-active-link"
                      >
                        <item.icon className="mr-4 flex-shrink-0 h-6 w-6 text-gray-700" aria-hidden="true" />
                        {item.name}
                      </NavLink>
                    ))}
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>

        <div className="flex-1 overflow-auto focus:outline-none">
          <div className="relative z-0 flex-shrink-0 flex h-16 bg-white border-b border-gray-200 lg:border-none">
            <button
              type="button"
              className="px-4 border-r border-gray-200 text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-500 lg:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <MenuAlt1Icon className="h-6 w-6" aria-hidden="true" />
            </button>
            {/* Search bar */}
            <div className="flex-1 px-4 flex justify-between sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
              <div className="flex-1 flex">
                {(false) && (
                  <form className="w-full flex md:ml-0" action="#" method="GET">
                    <label htmlFor="search-field" className="sr-only">
                      Search
                    </label>
                    <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                      <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none" aria-hidden="true">
                        <SearchIcon className="h-5 w-5" aria-hidden="true" />
                      </div>
                      <input
                        id="search-field"
                        name="search-field"
                        className="block w-full h-full pl-8 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-0 focus:border-transparent sm:text-sm"
                        placeholder="Search inSoTo"
                        type="search"
                      />
                    </div>
                  </form>
                )}
              </div>
              <div className="ml-4 flex items-center md:ml-6">
                <button
                  type="button"
                  className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                >
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button>

                {/* Profile dropdown */}
                <Menu as="div" className="ml-3 relative">
                  <div>
                    <ProfileMenu />
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="/profil"
                            className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                          >
                            Your Profile
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="/settings"
                            className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                          >
                            Settings
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="/logout"
                            className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                          >
                            Logout
                          </a>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>
          <main className="flex-1 relative pb-8 z-0 overflow-y-auto bg-gray-200">
            <div className="mt-8">
                <Switch>
                  <Route path="/data">
                    <Data />
                  </Route>
                  <Route path="/influencer">
                    <Influencer />
                  </Route>
                  <Route path="/dashboard">
                    <Dashboard />
                  </Route>
                  <Route path="/logout">
                    <Logout />
                  </Route>
                  <Route path="/">
                    <Redirect to="/influencer" />
                  </Route>
                </Switch>
            </div>
          </main>
        </div>
      </div>
      <Ping />
    </Router>
  )
}

export default App;
