import React from 'react';
import Cell from './cell';

function Row(props) {
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  return (
    <tr>
      {props.columns.map((column, index) => (
        <td
          className="px-6 py-4 whitespace-nowrap"
          key={'data-row-' + props.data.pk + '-' + index}
          className={classNames(
            'px-6 py-4',
            (column.options
              && column.options.whitespace
              && column.options.whitespace === 'wrap') ? 'whitspace-wrap' : 'whitespace-nowrap',
          )}
        >
          {(column.type === 'dataset') && (
            <Cell cell={column} data={props.data} />
          )}
          {(column.type === 'group' && (!column.options.hideIfEmpty || (column.options.hideIfEmpty && props.data[column.options.hideIfEmpty] !== ''))) && (
            <>
              {column.elements.map((element, index2) => (
                <Cell
                  key={'data-row-' + props.data.pk + '-' + index + '-cell-' + index2}
                  cell={element}
                  data={props.data}
                  labelAsPrefix={column.options.labelAsPrefix}
                />
              ))}
            </>
          )}
          {(column.type === 'actions') && (
            <>
              {props.actions.map((action, index) => (
                <div key={'action-row-' + props.data.pk + '-' + index} className="float-left">
                  <button
                    type="button"
                    className={classNames(
                      'inline-flex float-left mr-0.5 mb-0.5 items-center px-2 py-1 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
                      action.action.classNames
                    )}
                    onClick={()=> {
                      if (action.action.type === 'delete') {
                        if (props.deleteHandler) {
                          props.deleteHandler(props.data)
                        }
                      } else if(action.action.onClick) {
                        action.action.onClick(props.data)
                      }
                    }}
                  >
                      {action.action.content}
                  </button>
              </div>
              ))}
            </>
          )}
        </td>
      ))}
    </tr>
  );
}

export default Row;
