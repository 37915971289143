function Dashboard() {
  return (
    <>
      <h2>Dashboard</h2>
      <button onClick={() => { throw new Error('foo') }}>Break the world</button>
    </>
  );
};

export default Dashboard
