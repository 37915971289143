import {Link, NavLink, Route, Switch} from "react-router-dom";
import React from "react";
import InfluencerDetailTikTok from "./InfluencerDetailTikTok";
import InfluencerDetailOverview from "./InfluencerDetailOverview";
import DataTable from "./DataTable";
import {EyeIcon, NewspaperIcon, PencilIcon, TrashIcon, UploadIcon} from "@heroicons/react/solid";


function InfluencerDetailInstagramStories(props) {
  const data = props.data;
  const config = {
    title: '',
    description: 'Fügen Sie einen neuen Influencer hinzu.',
    url: '/api/stories/?influencer=' + data.pk,
    noAdd: true,
    fields: [
      {
        type: 'group',
        caption: undefined,
        fields: [
          {
            name: 'first_name',
            type: 'text',
            label: 'Vorname',
            required: true,
            default: '',
          },
          {
            name: 'last_name',
            type: 'text',
            label: 'Nachname',
            required: true,
            default: '',
          },
        ]
      },
      {
        type: 'group',
        caption: 'Networks',
        fields: [
          {
            name: 'instagram',
            type: 'text',
            label: 'Instagram Account',
            help: 'e.g. cristiano'
          },
          {
            name: 'tiktok',
            type: 'text',
            label: 'TikTok Account',
            help: 'e.g. @_._cristiano'
          }
        ]
      },
    ],
    columns: [
      {
        title: 'Story bild',
        column: 'image',
        type: 'dataset',
        options: {
          format: 'portrait',
        },
      },
      {
        title: 'Posted',
        column: 'posted',
        type: 'dataset',
        options: {
          format: 'datetime',
        },
      },
      {
        title: 'Clicks',
        column: 'clicks',
        type: 'dataset',
      },
      {
        title: 'Reach',
        column: 'reach',
        type: 'dataset',
      },
      {
        title: 'Partner',
        column: 'paidPartner.partner',
        type: 'dataset',
      },
      {
        title: 'Aktionen',
        type: 'actions',
      },
    ],
    actions: [
      {
        action: {
          classNames: "bg-red-600 hover:bg-red-700",
          type: 'delete',
          content: (
            <TrashIcon className="h-4 w-4" aria-hidden="true" />
          )
        },
      }
    ]
  };

  return (
    <>
       <br />
       <DataTable config={config} />
    </>
  );
}

export default InfluencerDetailInstagramStories;
