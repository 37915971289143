import React from "react";
import {Menu} from '@headlessui/react'
import {ChevronDownIcon} from "@heroicons/react/solid";

import { useStoreState } from "easy-peasy";


function ProfilMenu() {
  const image = ""
  const name = ""

  const state = useStoreState((state) => state);

  return (
    <>
      <Menu.Button className="max-w-xs bg-white rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 lg:p-2 lg:rounded-md lg:hover:bg-gray-50">
        {(false) && (
          <img
            className="h-8 w-8 rounded-full"
            src={image}
            alt={name}
          />
        )}
        <span className="hidden ml-3 text-gray-700 text-sm font-medium lg:block">
          <span className="sr-only">Open user menu for </span>{state.user.first_name} {state.user.last_name}
        </span>
        <ChevronDownIcon
          className="hidden flex-shrink-0 ml-1 h-5 w-5 text-gray-400 lg:block"
          aria-hidden="true"
        />
      </Menu.Button>
    </>
  )
}

export default ProfilMenu;
