import React, { Fragment, useState, useEffect } from 'react'
import * as _ from "lodash";
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import {PlusCircleIcon} from "@heroicons/react/solid";
import Tag from "../../components/tag";
import { CompactPicker as ColorPicker } from 'react-color';
import { ArrowsExpandIcon, PencilIcon, TrashIcon } from '@heroicons/react/solid'

function ImageBrowser(props) {
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  const opened = props.isOpened;
  const lateImagesLoaded = props.lateImagesLoaded;

  const [loading, setLoading] = React.useState(true);
  const [images, setImages] = React.useState([]);
  const [choosenImage, setChoosenImage] = React.useState(undefined);


  useEffect(() => {
    loadData()
  }, []);

  function loadData() {
    setLoading(() => true);
    window.api.get(props.imageListUrl)
      .then(function(response) {
        if (response.data && response.data.images) {
          //console.log(response.data.images)
          setImages(() => response.data.images)
          if (lateImagesLoaded) {
            lateImagesLoaded(response.data.images)
          }
        }
        setLoading(() => false);
      })
      .catch(function (error) {
        setLoading(() => false);
      });
  }

  function closeHandler(event) {
    if (props.closeHandler) props.closeHandler(event, choosenImage);

  }

  function submitHandler(event) {
    event.preventDefault();
    closeHandler(event);
  }

  return (
    <>
      <Transition.Root show={opened} as={Fragment}>
        <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={closeHandler}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity pointer-events-none" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                      Image Browser
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Bitte wähle eins der folgenden Bilder
                      </p>
                    </div>
                  </div>
                  <div className="mt-2 grid grid-cols-4 gap-4 max-h-96 overflow-y-scroll">
                    {images.map((image, index) => (
                      <div
                        key={'imagebrowser-' + index}
                        className={classNames(
                        (image == choosenImage) ? 'border-blue-500' : 'border-gray-300',
                        'border-4 cursor-pointer'
                      )}
                           onClick={() => {
                             setChoosenImage(() => image)
                           }}
                      >
                        <img src={image.path} />
                      </div>
                    ))}
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                    onClick={submitHandler}
                  >
                    wählen
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

export default ImageBrowser;
