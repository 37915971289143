import { useState } from 'react';
import * as Sentry from "@sentry/react";

export default function useToken() {
  const getToken = () => {
    const tokenString = localStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    const at = userToken?.access_token
    if (at) {
      Object.assign(window.api.defaults, {headers: {Authorization: 'Bearer ' + at}});
    } else {
      Object.assign(window.api.defaults, {headers: {Authorization: ''}});
    }
    return at;
  };

  const [token, setToken] = useState(getToken());

  const saveToken = userToken => {
    if (userToken.access_token) {
      Object.assign(window.api.defaults, {headers: {Authorization: 'Bearer ' + userToken.access_token}});
    } else {
      Object.assign(window.api.defaults, {headers: {Authorization: ''}});
    }
    localStorage.setItem('token', JSON.stringify(userToken));
    setToken(userToken.access_token);
  };

  const clearToken = () => {
    Sentry.setContext("user", {
      id: undefined,
      username: '',
    });
    Object.assign(window.api.defaults, {headers: {Authorization: ''}});
    localStorage.removeItem('token');
    setToken(undefined);
  }

  return {
    setToken: saveToken,
    clearToken,
    token
  }
}
