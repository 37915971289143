import React from 'react';
import useToken from "../../components/Helper/useToken";

function Logout() {
  const { clearToken } = useToken();
  clearToken();
  window.location = '/';

  return (
    <></>
  );
}

export default Logout;
