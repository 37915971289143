import React, {useState} from 'react';
import {DebounceInput} from 'react-debounce-input';

function DataTableSearch(props) {
  const config = props.config;

  const [query, setQuery] = useState(undefined);

  React.useEffect(() => {

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  function handleFieldChange(event) {
    setQuery(() => event.target.value);
    props.handleSearchChange(event.target.value)
  }

  return (
    <>
      <div className="flex-1 flex">
        <form className="w-full flex md:ml-0 mb-4" action="#" method="GET">
          <label htmlFor="search-field" className="sr-only">Search</label>
          <div className="relative w-full text-gray-400 focus-within:text-gray-600">
            <div className="absolute inset-y-0 left-2 flex items-center pointer-events-none" aria-hidden="true">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5" aria-hidden="true">
                <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
              </svg>
            </div>
            <DebounceInput
              minLength={2}
              debounceTimeout={300}
              id="search-field"
              name="search-field"
              className="block rounded-md w-full h-full pl-9 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-0 focus:border-transparent sm:text-sm"
              placeholder={'Durchsuche ' + config.title}
              type="search"
              value={query}
              onChange={(event) => {
                handleFieldChange(event)
              }}
            />
          </div>
        </form>
      </div>
    </>
  );
}

export default DataTableSearch;
