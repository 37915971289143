import _ from 'lodash'
import I18n from 'i18n-js'

import de from '../locales/de'
import deFormats from '../locales/formats/de'
import en from '../locales/en'
import enFormats from '../locales/formats/en'

const locales = ['de', 'en']

if (Array.isArray(locales)) {
  I18n.locale = 'de' || locales[0].languageTag
}

const deLang = {}
_.assignIn(deLang, de, deFormats)
const enLang = {}
_.assignIn(enLang, en, enFormats)

I18n.fallbacks = true
I18n.translations = {
  de: deLang,
  en: enLang,
}


export default I18n
