import { Fragment, useState, useEffect, createRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon, ChevronDownIcon, ChevronUpIcon, BeakerIcon, ArchiveIcon } from '@heroicons/react/outline'

import ImageBrowser from "../imagebrowser/ImageBrowser";

const sleep = (milliseconds) => {
  return new Promise(resolve => setTimeout(resolve, milliseconds))
}

function SedCardNew(props) {
  const opened = props.isOpened;

  const fieldList = initFieldList();
  const [fields, setFields] = useState(fieldList);
  const [files, setFiles] = useState([]);
  const [imageBrowserVisible, setImageBrowserVisible] = useState(false);
  const [imageBrowserField, setImageBrowserField] = useState(undefined);
  let imageBrowserRef = createRef();

  useEffect(() => {
    setDefaults() // this will only get called when the component mounts. Same as `componentDidMount`
  }, [props.config])

  const [fieldGroupsOpened, setFieldGroupsOpened] = useState({
    'Instagram': false,
    'TikTok': false,
    'Twitch': false,
    'Youtube': false,
    'Splits': false,
    'Basis Info': true,
    'Bilder': false,
  });
  const [rerender, setRerender] = useState(Math.random())

  function handleFieldChange(event, field) {
    const newFields = fields;
    newFields[field] = event.target.value;
    setFields({...newFields});
  }

  function handleFileChange(event, field) {
    const newFiles = files;
    newFiles[field] = event.target.files[0];
    setFiles({...newFiles});
  }

  function initFieldList() {
    const fieldList = {};
    props.config.fields.map((fieldGroups) => {
      fieldGroups.fields.map((field) => {
        fieldList[field.name] = '';
        return null;
      });
      return null;
    });

    return fieldList;
  }

  const lateImageFields = [];

  function lateImagesLoaded(images) {
    lateImageFields.map((name) => {
      var rndImage = Math.floor(Math.random() * (images.length - 1))
      const im = images[rndImage].path
      props.config.fields.map((fieldGroups) => {
        fieldGroups.fields.map((field) => {
          if (field.name == name) {
            field.overrideData = im;
          }
        });
      });

      fieldList[name] = im;
    });
    setDefaults()
    setRerender(() => Math.random())
  }

  function setDefaults() {
    const fieldList = fields;
    const self = this;
    props.config.fields.map((fieldGroups) => {
      fieldGroups.fields.map((field) => {
        if (!fieldList[field.name]) {
          fieldList[field.name] = '';
        }

        if (field.archiveData) {
          fieldList[field.name] = field.archiveData;
          setRerender(() => Math.random())
        } else if (field.overrideData) {
          if (field.type == 'imagebrowser' && field.overrideData == 'random') {
            //self.waitForImages(field)
            lateImageFields.push(field.name)

          } else {
            fieldList[field.name] = field.overrideData;
            setRerender(() => Math.random())
          }
        }
        return null;
      });
      return null;
    });

    setFields(() => fieldList)
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  function closeHandler(event) {
    if (props.closeHandler) props.closeHandler(event);
    //setFields(() => initFieldList());
  }

  function submitHandler(event) {
    event.preventDefault();
    if (props.saveHandler) props.saveHandler(event, fields, files);
    closeHandler(event);
  }

  return (
    <>
      <Transition.Root show={opened} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 overflow-hidden" onClose={() => {}}>
          <div className="absolute inset-0 overflow-hidden">
            <Dialog.Overlay className="absolute inset-0" />
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>
            <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="w-screen max-w-2xl">
                  <form className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll" onSubmit={submitHandler}>
                    <div className="flex-1">
                      {/* Header */}
                      <div className="px-4 py-6 bg-gray-50 sm:px-6">
                        <div className="flex items-start justify-between space-x-3">
                          <div className="space-y-1">
                            <Dialog.Title className="text-lg font-medium text-gray-900">Sed Card erstellen</Dialog.Title>
                            <p className="text-sm text-gray-500">
                              {props.config.description}
                            </p>
                          </div>
                          <div className="h-7 flex items-center">
                            <button
                              type="button"
                              className="text-gray-400 hover:text-gray-500"
                              onClick={closeHandler}
                            >
                              <span className="sr-only">Close panel</span>
                              <XIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>

                      {props.config.fields.map((fieldsGroup, index) => (
                        <div
                          key={'fieldgroup-add-new-' + index}
                        >
                          {(fieldsGroup.type === 'group') && (
                            <div className="py-6 space-y-6 sm:py-0 sm:space-y-0">
                              {(fieldsGroup.caption) && (
                                <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-2">
                                  <>{fieldGroupsOpened['Instagram'] }</>
                                  <h2>{fieldsGroup.caption}</h2>
                                  {(fieldsGroup.foldable) && (
                                    <div className="h-7 flex items-center">
                                      <button
                                        type="button"
                                        className="text-gray-400 hover:text-gray-500"
                                        onClick={() => {
                                          fieldGroupsOpened[fieldsGroup.caption] = !(fieldGroupsOpened[fieldsGroup.caption] ? fieldGroupsOpened[fieldsGroup.caption] : false);
                                          setFieldGroupsOpened(() => fieldGroupsOpened);
                                          setRerender(() => Math.random())
                                        }}
                                      >
                                        <span className="sr-only"></span>
                                        {(fieldGroupsOpened[fieldsGroup.caption]) ? (
                                          <ChevronUpIcon className="h-6 w-6" aria-hidden="true" />
                                        ) : (
                                          <ChevronDownIcon className="h-6 w-6" aria-hidden="true" />
                                        )}
                                      </button>
                                    </div>
                                  )}
                                </div>
                              )}
                              {fieldsGroup.fields.map((field) => (
                                <div
                                  className={classNames(
                                    (fieldsGroup.foldable && !fieldGroupsOpened[fieldsGroup.caption] ? 'hidden' : ''),
                                  )}
                                  key={'field-' + field.name}
                                >
                                  {(field.type === 'text' || field.type === 'number') && (
                                    <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-2">
                                      <div className="flex relative">
                                        <label
                                          htmlFor={'field-' + field.name}
                                          className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                        >
                                          {field.label}
                                        </label>
                                        <div className="flex-grow text-right absolute top-0 right-0">
                                        {(field.overrideData) && (
                                          <button
                                            type="button"
                                            className={classNames(
                                              'mr-1 inline-flex h-9 justify-center py-2 px-1 border border-transparent shadow-sm text-sm font-medium rounded-md bg-gray-200 hover:bg-gray-300',
                                              (field.overrideData == fields[field.name] ? 'bg-gray-500 hover:bg-gray-600 text-white' : 'bg-gray-200  hover:bg-gray-300 text-indigo-600 '),
                                            )}
                                            onClick={(e) => {
                                              e.preventDefault();
                                              handleFieldChange({ target: { value: field.overrideData }}, field.name);
                                            }}
                                          >
                                            <BeakerIcon className="h-4 w-4" aria-hidden="true" />
                                          </button>
                                        )}
                                        {(field.archiveData) && (
                                          <button
                                            type="button"
                                            className={classNames(
                                              'mr-1 inline-flex h-9 justify-center py-2 px-1 border border-transparent shadow-sm text-sm font-medium rounded-md bg-gray-200 hover:bg-gray-300',
                                              (field.archiveData == fields[field.name] ? 'bg-gray-500 hover:bg-gray-600 text-white' : 'bg-gray-200  hover:bg-gray-300 text-indigo-600 '),
                                            )}
                                            onClick={(e) => {
                                              e.preventDefault();
                                              handleFieldChange({ target: { value: field.archiveData }}, field.name);
                                            }}
                                          >
                                            <ArchiveIcon className="h-4 w-4" aria-hidden="true" />
                                          </button>
                                        )}
                                        </div>
                                      </div>
                                      <div className="sm:col-span-2">
                                        <input
                                          type={field.type}
                                          name={'field-' + field.name}
                                          id={'field-' + field.name}
                                          className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                                          value={fields[field.name]}
                                          onChange={(event) => {
                                            handleFieldChange(event, field.name)
                                          }}
                                        />
                                      </div>
                                    </div>
                                  )}
                                  {(field.type === 'select') && (
                                    <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-2">
                                      <div className="flex relative">
                                        <label
                                          htmlFor={'field-' + field.name}
                                          className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                        >
                                          {field.label}
                                        </label>
                                        <div className="flex-grow text-right absolute top-0 right-0">
                                        {(field.overrideData) && (
                                          <button
                                            type="button"
                                            className={classNames(
                                              'mr-1 inline-flex h-9 justify-center py-2 px-1 border border-transparent shadow-sm text-sm font-medium rounded-md bg-gray-200 hover:bg-gray-300',
                                              (field.overrideData == fields[field.name] ? 'bg-gray-500 hover:bg-gray-600 text-white' : 'bg-gray-200  hover:bg-gray-300 text-indigo-600 '),
                                            )}
                                            onClick={(e) => {
                                              e.preventDefault();
                                              handleFieldChange({ target: { value: field.overrideData }}, field.name);
                                            }}
                                          >
                                            <BeakerIcon className="h-4 w-4" aria-hidden="true" />
                                          </button>
                                        )}
                                          {(field.archiveData) && (
                                            <button
                                              type="button"
                                              className={classNames(
                                                'mr-1 inline-flex h-9 justify-center py-2 px-1 border border-transparent shadow-sm text-sm font-medium rounded-md bg-gray-200 hover:bg-gray-300',
                                                (field.archiveData == fields[field.name] ? 'bg-gray-500 hover:bg-gray-600 text-white' : 'bg-gray-200  hover:bg-gray-300 text-indigo-600 '),
                                              )}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                handleFieldChange({ target: { value: field.archiveData }}, field.name);
                                              }}
                                            >
                                            <ArchiveIcon className="h-4 w-4" aria-hidden="true" />
                                          </button>
                                          )}
                                        </div>
                                      </div>
                                      <div className="sm:col-span-2">
                                        <select
                                          name={'field-' + field.name}
                                          id={'field-' + field.name}
                                          className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                                          value={fields[field.name]}
                                          onChange={(event) => {
                                            handleFieldChange(event, field.name)
                                          }}
                                          defaultValue={(field.archiveData) ? field.archiveData : field.overrideData}
                                        >
                                          {field.options.map((option, index) => (
                                            <option
                                              value={option.val}
                                            >{option.name}</option>
                                          ))}
                                        </select>
                                      </div>
                                    </div>
                                  )}
                                  {(field.type === 'longtext') && (
                                    <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-2">
                                      <div className="relative">
                                        <label
                                          htmlFor={'field-' + field.name}
                                          className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                        >
                                          {field.label}
                                        </label>
                                        <div className="flex-grow absolute top-0 right-0">
                                          {(field.overrideData) && (
                                            <button
                                              type="button"
                                              className={classNames(
                                                'mr-1 inline-flex h-9 justify-center py-2 px-1 border border-transparent shadow-sm text-sm font-medium rounded-md bg-gray-200 hover:bg-gray-300',
                                                (field.overrideData == fields[field.name] ? 'bg-gray-500 hover:bg-gray-600 text-white' : 'bg-gray-200  hover:bg-gray-300 text-indigo-600 '),
                                              )}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                handleFieldChange({ target: { value: field.overrideData }}, field.name);
                                              }}
                                            >
                                              <BeakerIcon className="h-4 w-4" aria-hidden="true" />
                                            </button>
                                          )}
                                          {(field.archiveData) && (
                                            <button
                                              type="button"
                                              className={classNames(
                                                'mr-1 inline-flex h-9 justify-center py-2 px-1 border border-transparent shadow-sm text-sm font-medium rounded-md bg-gray-200 hover:bg-gray-300',
                                                (field.archiveData == fields[field.name] ? 'bg-gray-500 hover:bg-gray-600 text-white' : 'bg-gray-200  hover:bg-gray-300 text-indigo-600 '),
                                              )}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                handleFieldChange({ target: { value: field.archiveData }}, field.name);
                                              }}
                                            >
                                            <ArchiveIcon className="h-4 w-4" aria-hidden="true" />
                                          </button>
                                          )}
                                        </div>
                                      </div>
                                      <div className="sm:col-span-2">
                                        <textarea
                                          type="text"
                                          name={'field-' + field.name}
                                          id={'field-' + field.name}
                                          className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                                          value={fields[field.name]}
                                          onChange={(event) => {
                                            handleFieldChange(event, field.name)
                                          }}
                                        />
                                      </div>
                                    </div>
                                  )}
                                  {(field.type === 'datetime') && (
                                    <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-2">
                                      <div>
                                        <label
                                          htmlFor={'field-' + field.name}
                                          className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                        >
                                          {field.label}
                                        </label>
                                      </div>
                                      <div className="sm:col-span-2">
                                        <input
                                          type="datetime-local"
                                          name={'field-' + field.name}
                                          id={'field-' + field.name}
                                          className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                                          value={fields[field.name]}
                                          onChange={(event) => {
                                            handleFieldChange(event, field.name)
                                          }}
                                        />
                                      </div>
                                    </div>
                                  )}
                                  {(field.type == 'imagebrowser') && (
                                    <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-2">
                                      <div className="relative">
                                        <label
                                          htmlFor={'field-' + field.name}
                                          className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                        >
                                          {field.label}
                                        </label>
                                        <div className="flex-grow absolute top-0 right-0">
                                          {(field.overrideData) && (
                                            <button
                                              type="button"
                                              className={classNames(
                                                'mr-1 inline-flex h-9 justify-center py-2 px-1 border border-transparent shadow-sm text-sm font-medium rounded-md bg-gray-200 hover:bg-gray-300',
                                                (field.overrideData == fields[field.name] ? 'bg-gray-500 hover:bg-gray-600 text-white' : 'bg-gray-200  hover:bg-gray-300 text-indigo-600 '),
                                              )}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                handleFieldChange({ target: { value: field.overrideData }}, field.name);
                                              }}
                                            >
                                              <BeakerIcon className="h-4 w-4" aria-hidden="true" />
                                            </button>
                                          )}
                                          {(field.archiveData) && (
                                            <button
                                              type="button"
                                              className={classNames(
                                                'mr-1 inline-flex h-9 justify-center py-2 px-1 border border-transparent shadow-sm text-sm font-medium rounded-md bg-gray-200 hover:bg-gray-300',
                                                (field.archiveData == fields[field.name] ? 'bg-gray-500 hover:bg-gray-600 text-white' : 'bg-gray-200  hover:bg-gray-300 text-indigo-600 '),
                                              )}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                handleFieldChange({ target: { value: field.archiveData }}, field.name);
                                              }}
                                            >
                                            <ArchiveIcon className="h-4 w-4" aria-hidden="true" />
                                          </button>
                                          )}
                                        </div>
                                      </div>
                                      <div className="sm:col-span-2">
                                        <div className="flex">
                                          <div
                                            className="w-16 h-16 bg-gray-300 mr-6"
                                          >
                                            <img src={fields[field.name]} className="max-h-16 m-auto" />
                                          </div>
                                          <div className="items-center">
                                            <button
                                              type="button"
                                              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                              onClick={() => {
                                                setImageBrowserField(() => field.name)
                                                setImageBrowserVisible(() => true)
                                              }}
                                            > Bild auswählen</button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  {(field.type === 'file') && (
                                    <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-2">
                                      <div>
                                        <label
                                          htmlFor={'field-' + field.name}
                                          className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                        >
                                          {field.label}
                                        </label>
                                      </div>
                                      <div className="sm:col-span-2">
                                        <input
                                          type="file"
                                          name={'field-' + field.name}
                                          id={'field-' + field.name}
                                          className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                                          onChange={(event) => {
                                            handleFileChange(event, field.name)
                                          }}
                                        />
                                      </div>
                                    </div>
                                  )}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      ))}
                    </div>

                    {/* Action buttons */}
                    <div className="flex-shrink-0 px-4 border-t border-gray-200 py-5 sm:px-6">
                      <div className="space-x-3 flex justify-end">
                        <button
                          type="button"
                          className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          onClick={closeHandler}
                        >
                          {(props.config.cancelBtnLabel) ? props.config.cancelBtnLabel : 'Cancel'}
                        </button>
                        <button
                          type="submit"
                          className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          {(props.config.saveBtnLabel) ? props.config.saveBtnLabel : 'Create'}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <ImageBrowser
        ref={(imageBrowserRef) => {this.imageBrowserRef = imageBrowserRef;}}
        isOpened={imageBrowserVisible}
        lateImagesLoaded={lateImagesLoaded}
        imageListUrl={'api/influencer/' + props.config.influencerId + '/imageList/'}
        closeHandler={(event, image) => {
          setImageBrowserVisible(() => false)
          const newFields = fields;
          newFields[imageBrowserField] = image.path;
          setFields({...newFields});
        }}
      />
    </>
  );
}

export default SedCardNew;
