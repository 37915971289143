import React, { Fragment, useState } from 'react'
import Slider, { Range } from 'rc-slider'
import 'rc-slider/assets/index.css'
import _ from 'lodash'

function DataTableFiltersGenderSplit(props) {
  const filter = props.filter;

  const [minValMale, setMinValMale] = useState(0);
  const [minValFemale, setMinValFemale] = useState(0);
  const [updateState, setUpdateState] = useState(0);

  React.useEffect((a) => {
    props.handleFilterChange(filter.key, {'value':{'minMale':minValMale, 'minFemale':minValFemale}});
  }, [updateState]);

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  return (
    <>
      <div>
        <label htmlFor={filter.key} className="block text-sm font-medium text-gray-700">
          {filter.title}
        </label>
        <div className="grid grid-rows-2 gap-1">
          <div>
            <div className="flex flex-col">
              <div className="flex-grow flex flex-row"
                   style={{
                     opacity: (minValMale > 0.0) ? 1.0 : 0.4
                   }}
              >
                <div className="flex-grow pr-4 pl-2 pt-1">
                  <Slider min={0} max={100} defaultValue={minValMale}
                          onAfterChange={(val) => {
                            setMinValFemale(() => 0);
                            setMinValMale(() => val);
                            setUpdateState(() => Math.random());
                          }}
                          trackStyle={{
                            backgroundColor: 'rgb(5, 150, 105)',
                            height: 10,
                          }}
                          railStyle={{
                            backgroundColor: 'rgb(107, 114, 128)',
                            height: 10,
                          }}
                          handleStyle={{
                            height: 20,
                            width: 20,
                          }}
                  />
                </div>
                <div className="flex-none w-13 text-right font-medium text-gray-700">
                  {minValMale}% ♂
                </div>
              </div>
              <div className="flex-grow flex flex-row"
                   style={{
                     opacity: (minValFemale > 0.0) ? 1.0 : 0.4
                   }}
              >
                <div className="flex-grow pr-4 pl-2 pt-1">
                  <Slider min={0} max={100} defaultValue={minValFemale}
                          onAfterChange={(val) => {
                            setMinValMale(() => 0);
                            setMinValFemale(() => val);
                            setUpdateState(() => Math.random());
                          }}
                          trackStyle={{
                            backgroundColor: 'rgb(5, 150, 105)',
                            height: 10,
                          }}
                          railStyle={{
                            backgroundColor: 'rgb(107, 114, 128)',
                            height: 10,
                          }}
                          handleStyle={{
                            height: 20,
                            width: 20,
                          }}
                  />
                </div>
                <div className="flex-none w-13 text-right font-medium text-gray-700">
                  {minValFemale}% ♀
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DataTableFiltersGenderSplit;
