import {Link, useRouteMatch} from "react-router-dom";
import React from "react";
import {UsersIcon} from "@heroicons/react/outline";

function InfluencerDetailOverview(props) {
  let match = useRouteMatch();
  const data = props.data;

  return (
    <div className="mt-6 max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
      {(data.partner && data.partner.length > 0) && (
        <dl className="mt-5 mr-5 w-100">
          <div className="absolute bg-white rounded-md pt-5 px-4">
            <UsersIcon className="h-12 w-12" aria-hidden="true" />
          </div>
          <div
            className="relative pt-5 px-4 pb-5 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden"
          >
            <dt>
              <p className="ml-16 text-lg pb-6 font-medium text-gray-500 truncate">
                Partnerships
              </p>
            </dt>
            {data.partner.map((partner, index) => (
              <>
                <dt>
                  <p className="ml-16 text-sm font-medium text-gray-500 truncate">{partner.partner}</p>
                </dt>
                <dd className="ml-16 pb-2 flex items-baseline sm:pb-3">
                  <p className="text-2xl font-semibold text-gray-900"></p>
                </dd>
              </>
            ))}
          </div>
        </dl>
      )}

      <div class="flex">
        {(data.instagram) && (
          <dl className="mt-5 mr-5 flex">
          <div className="absolute bg-white rounded-md pt-5 px-4">
            <img src="/gfx/instagram.webp" width={50} />
          </div>
          <div
            className="relative pt-5 px-4 pb-5 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden"
          >
            <dt>
              <p className="ml-16 text-sm font-medium text-gray-500 truncate">Follower</p>
            </dt>
            <dd className="ml-16 pb-2 flex items-baseline sm:pb-3">
              <p className="text-2xl font-semibold text-gray-900">{data.instagram_follower}</p>
            </dd>
            <dt>
              <p className="ml-16 text-sm font-medium text-gray-500 truncate">Follows</p>
            </dt>
            <dd className="ml-16 pb-2 flex items-baseline sm:pb-3">
              <p className="text-2xl font-semibold text-gray-900">{data.instagram_follows}</p>
            </dd>
            <dt>
              <p className="ml-16 text-sm font-medium text-gray-500 truncate">Posts</p>
            </dt>
            <dd className="ml-16 pb-2 flex items-baseline sm:pb-3">
              <p className="text-2xl font-semibold text-gray-900">{data.instagram_posts}</p>
            </dd>
            <dt>
              <p className="ml-16 text-sm font-medium text-gray-500 truncate">Engagement Rate (Last 6 Posts)</p>
            </dt>
            <dd className="ml-16 pb-12 flex items-baseline sm:pb-12">
              <p className="text-2xl font-semibold text-gray-900">{data.instagram_engagement_rate_posts}</p>
            </dd>
            <div className="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
              <div className="text-sm flex justify-between">
                <Link
                  className="font-medium text-indigo-600 hover:text-indigo-500"
                  to={{ pathname: ('https://instagram.com/' + data.instagram) }}
                  target="_blank"
                >
                  Instagram Profil
                </Link>

                <Link
                  className="font-medium text-indigo-600 hover:text-indigo-500"
                  to={`/influencer/${data.pk}/instagram`}
                >
                  {' '}
                  Details
                </Link>
              </div>
            </div>
          </div>
        </dl>
        )}

        {(data.tiktok) && (
          <dl className="mt-5 mr-5 flex">
          <div className="absolute bg-white rounded-md pt-5 px-4">
            <img src="/gfx/tiktok.webp" width={50} />
          </div>
          <div
            className="relative pt-5 px-4 pb-5 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden"
          >
            <dt>
              <p className="ml-16 text-sm font-medium text-gray-500 truncate">Follower</p>
            </dt>
            <dd className="ml-16 pb-2 flex items-baseline sm:pb-3">
              <p className="text-2xl font-semibold text-gray-900">{data.tiktok_follower}</p>
            </dd>
            <dt>
              <p className="ml-16 text-sm font-medium text-gray-500 truncate">Follows</p>
            </dt>
            <dd className="ml-16 pb-2 flex items-baseline sm:pb-3">
              <p className="text-2xl font-semibold text-gray-900">{data.tiktok_follows}</p>
            </dd>
            <dt>
              <p className="ml-16 text-sm font-medium text-gray-500 truncate">Posts</p>
            </dt>
            <dd className="ml-16 pb-2 flex items-baseline sm:pb-3">
              <p className="text-2xl font-semibold text-gray-900">{data.tiktok_posts}</p>
            </dd>
            <dt>
              <p className="ml-16 text-sm font-medium text-gray-500 truncate">Engagement Rate (Last 6 Posts)</p>
            </dt>
            <dd className="ml-16 pb-2 flex items-baseline sm:pb-12">
              <p className="text-2xl font-semibold text-gray-900">{data.tiktok_engagement_rate_posts}</p>
            </dd>
            <div className="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
              <div className="text-sm flex justify-between">
                <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                  {' '}
                  TikTok Profil
                </a>
                <Link
                  className="font-medium text-indigo-600 hover:text-indigo-500"
                  to={{ pathname: ('https://tiktok.com/' + data.tiktok) }}
                  target="_blank"
                >
                  TikTok Profil
                </Link>

                <Link
                  to={`/influencer/${data.pk}/tiktok`}
                  className="font-medium text-indigo-600 hover:text-indigo-500">
                  {' '}
                  Details
                </Link>
              </div>
            </div>
          </div>
        </dl>
        )}
      </div>
    </div>
  );
}

export default InfluencerDetailOverview;
