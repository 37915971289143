import {Link} from "react-router-dom";
import Tag from "../tag";

function Cell(props) {
  const column = props.cell;

  if (column.options && column.options.format == 'tags') {
    const tags = props.data[column.column];
    return (
      <>
        {tags.map((tag, index) => (
          <Tag
            tag={tag}
            clickHandler={(tag) => {
            }}
          />
        ))}
      </>
    )
  }

  return (
    <div className="text-sm text-gray-900">
      {(props.labelAsPrefix) && (
        <>
          {column.title}:{' '}
        </>
      )}
      {(column.options && column.options.truncate) ? (
        (props.data[column.column].length > column.options.truncate) ? (
          props.data[column.column].substr(0, column.options.truncate) + ' ...'
        ) : (
          props.data[column.column].substr(0, column.options.truncate)
        )
      ) : (
        <>
          {(column.options && column.options.format === 'datetime') ? (
            //props.data[column.column].split('.')[0]
            new Intl.DateTimeFormat("de-DE", {
              dateStyle: 'full',
              timeStyle: 'long',
            }).format(new Date(props.data[column.column].split('.')[0]))
          ) : (
            <>
              {(column.options && (column.options.format === 'avatar' || column.options.format === 'portrait')) ? (
                <>
                  {(column.options.format === 'avatar') ? (
                    <img
                      className="inline-block h-12 w-12 rounded-full"
                      src={props.data[column.column]}
                      alt=""
                    />
                  ) : (
                    <img
                      className="inline-block h-48 w-27"
                      src={props.data[column.column]}
                      alt=""
                    />
                  )}
                </>
              ) : (
                <>
                  {(column.options && column.options.externalLink) ? (
                    <Link
                      className="underline text-blue-600 hover:text-blue-800"
                      to={{ pathname: column.options.externalLink.prefix + props.data[column.column] + column.options.externalLink.postfix}}
                      target="_blank"
                    >
                      {props.data[column.column]}
                    </Link>
                  ) : (
                    <>
                      {(column.options && column.options.format === 'number') ? (
                        new Intl.NumberFormat("de-DE", {}).format(props.data[column.column])
                      ) : (
                        <>
                          {(column.options && column.options.format === 'percent') ? (
                            new Intl.NumberFormat("de-DE", {
                              style: 'percent',
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                            }).format(props.data[column.column] / 100.0)
                          ) : (
                            <>
                              {(column.options && column.options.format === 'longtext') ? (
                                <>
                                  <p className={'break-all whitespace-normal'} style={{ maxWidth: 300, }}>
                                    {props.data[column.column]}
                                  </p>
                                </>
                              ) : (
                                <>
                                  {(column.column.indexOf('.') > -1) ? (
                                    <>
                                      {(props.data[column.column.split('.')[0]]) ? (
                                        <>{props.data[column.column.split('.')[0]][column.column.split('.')[1]]}</>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {props.data[column.column]}
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}

export default Cell;
