import React from 'react'

function Tag(props) {
  return (
    <div
      className="shadow-sm whitespace-nowrap mr-1 mb-1 text-xs inline-block items-center font-bold leading-sm uppercase px-3 py-1 rounded-full"
      style={{
        cursor: 'pointer',
        minWidth: 20,
        color: props.tag.colorText,
        backgroundColor: props.tag.colorBackground,
      }}
      onClick={() => {
        props.clickHandler(props.tag)
      }}
    >
      {(props.tag.tag) ? props.tag.tag : String.fromCharCode(160)}
    </div>
  );
};

export default Tag;
