import React from 'react';
import I18n from '../../helpers/I18n'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid'

function Pagination(props) {
  const from = props.offset + 1;
  const to = Math.min(props.count, props.offset + props.limit);

  return (
    <div className={
      'bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6' +
      ((props.loading) ? ' pbd-blur' : ' ')
    }>
      <div className="flex-1 flex justify-between sm:hidden">
        <button
          onClick={()=>props.clickHandler({offset:props.offset-props.limit})}
          className={
            'relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50' +
            ((props.offset <= 0) ? ' opacity-30' : '')
          }
        >
          Previous
        </button>
        <button
          disabled={props.offset>=props.count/props.limit}
          onClick={()=>props.clickHandler({offset:props.offset+props.limit})}
          className={
            'ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50' +
            ((props.offset + props.limit >= props.count) ? ' opacity-30' : '')
          }
        >
          Next
        </button>
      </div>
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            {I18n.t('TABLE.PAGINATION_SHOWING', {from, to, total: props.count})}
          </p>
        </div>
        <div>
          <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
            <button
              onClick={()=>props.clickHandler({offset:props.offset-props.limit})}
              disabled={props.offset <= 0}
              className={
                'relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50' +
                ((props.offset <= 0) ? ' opacity-30' : '')
              }
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <button
              disabled={props.offset+props.limit>=props.count}
              onClick={()=>props.clickHandler({offset:props.offset+props.limit})}
              className={
                'relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50' +
                ((props.offset + props.limit >= props.count) ? ' opacity-30' : '')
              }
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
        </div>
      </div>
    </div>
  )
}

export default Pagination;
