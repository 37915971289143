import DataTable from './DataTable'
import {PencilIcon, TrashIcon} from "@heroicons/react/solid";

function Data() {
  const title = 'Data';
  const url = "/api/data/";
  const config = {
    title: 'Data',
    url: '/api/data/',
    columns: [
      {
        title: 'ID',
        column: 'pk',
        type: 'dataset'
      },
      {
        title: 'Von',
        column: 'user',
        type: 'dataset',
      },
      {
        title: 'Wann',
        column: 'entered',
        type: 'dataset',
        options: {
          format: 'datetime'
        }
      },
      {
        title: 'Daten',
        column: 'data',
        type: 'dataset',
        options: {
          truncate: 20
        }
      },
      {
        title: 'Aktionen',
        type: 'actions',
      },
    ],
    actions: [
      {
        action: (
          <button
            type="button"
            className="inline-flex float-left mr-1 items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <PencilIcon className="h-4 w-4" aria-hidden="true" />
          </button>
        )
      },
      {
        //action: (<button className="bg-red-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">{I18n.t('TABLE.BUTTON_DELETE')}</button>),
        action: (
          <button
            type="button"
            className="inline-flex float-left mr-1 items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <TrashIcon className="h-4 w-4" aria-hidden="true" />
          </button>
        )
      }
    ]
  };

  return (
    <DataTable limit={20} config={config} url={url} title={title}></DataTable>
  )
}

export default Data;
