import axios from 'axios';

let baseURL = window.location.protocol + '//api.' + window.location.host + '/';
if (window.location.host.indexOf('localhost') > -1
    || window.location.host.indexOf('0.0.0.0') > -1
    || window.location.host.indexOf('127.0.0.1') > -1) {
  baseURL = 'http://0.0.0.0:8000/'
}

const api = axios.create({
  baseURL,
  timeout: 1000 * 60 * 2,
  headers: {'Authorization': ''}
});

const requestHandler = request => {
  return request;
};

const responseHandler = response => {
  return response;
};

const errorHandler = error => {
  if (error.message.indexOf('code 401') > -1) {
    if (window.location.href.indexOf('/logout') === -1) {
      window.location = '/logout'
    }
  }
  return Promise.reject(error);
};

api.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);

api.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);


export default api;
