import {createStore, action} from "easy-peasy";

const store = createStore({
  user: {
    first_name: 'Max',
    last_name: 'Mustermann',
  },
  setUser: action((state, payload) => {
    state.user.first_name = payload.first_name;
    state.user.last_name = payload.last_name;
  }),
});

export default store

