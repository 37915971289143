import {
  Link, NavLink,
  Route, Switch,
  useParams, useRouteMatch
} from "react-router-dom";
import React from 'react';
import _ from 'lodash';
import {
  NewspaperIcon,
} from '@heroicons/react/outline'
import InfluencerDetailOverview from "./InfluencerDetailOverview";
import InfluencerDetailInstagram from "./InfluencerDetailInstagram";
import InfluencerDetailTikTok from "./InfluencerDetailTikTok";

import SedCardNew from "../sedcard/SedCardNew";

const tabs = [
  { name: 'Overview', href: '#', current: true },
  { name: 'Instagram', href: '#', current: false },
  { name: 'TikTok', href: '#', current: false },
];


function InfluencerDetail(props) {
  let match = useRouteMatch();

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  const [createNewSedCard, setCreateNewSedCard] = React.useState(false);
  const [createNewSedCardLoading, setCreateNewSedCardLoading] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState({});

  let { influencerId } = useParams();
  const url = '/api/influencer/' + influencerId + '/';

  React.useEffect(() => {
    loadData();
  }, [props]);

  function loadData() {
    setLoading(() => true);
    window.api.get(url)
      .then(function(response) {
        setData(() => response.data);
        setLoading(() => false);
      })
      .catch(function (error) {
        setLoading(() => false);
      });
  }

  return (
    <>
      <article className="-mt-8 bg-white pb-12">
        {/* Profile header */}
        <div>
          <div>
            <img className="h-32 w-full object-cover lg:h-48" src={data.instagram_image} alt="" />
          </div>
          <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
              <div className="flex">
                <img
                  className="h-24 w-24 rounded-full ring-4 ring-white sm:h-32 sm:w-32"
                  src={data.instagram_image}
                  alt=""
                />
              </div>
              <div className="mt-6 sm:flex-1 sm:min-w-0 sm:flex sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
                <div className="sm:hidden 2xl:block mt-6 min-w-0 flex-1">
                  <h1 className="text-2xl font-bold text-gray-900 truncate">{data.first_name} {data.last_name}</h1>
                </div>
                <div className="mt-6 flex flex-col justify-stretch space-y-3 sm:flex-row sm:space-y-0 sm:space-x-4">
                  <button
                    type="button"
                    className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                    onClick={() => {
                      setCreateNewSedCard(() => true);
                      //window.open(window.api.defaults.baseURL + "sedcard/?ids=" + data.pk, "_blank")
                    }}
                  >
                    <NewspaperIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                    <span>Sed-Card</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="hidden sm:block 2xl:hidden mt-6 min-w-0 flex-1">
              <h1 className="text-2xl font-bold text-gray-900 truncate">{data.first_name} {data.last_name}</h1>
            </div>
          </div>
        </div>

        {/* Tabs */}
        <div className="mt-6 sm:mt-2 2xl:mt-5">
          <div className="border-b border-gray-200">
            <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
              <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                <NavLink
                  to={`/influencer/${data.pk}`}
                  className={classNames(
                    'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                    'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
                  )}
                  activeClassName={'border-pink-500 text-gray-900'}
                  exact={true}
                >
                  Overview
                </NavLink>
                <NavLink
                  to={`/influencer/${data.pk}/instagram`}
                  className={classNames(
                    'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                    'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
                  )}
                  activeClassName={'border-pink-500 text-gray-900'}
                  exact={false}
                >
                  Instagram
                </NavLink>
                <NavLink
                  to={`/influencer/${data.pk}/tiktok`}
                  className={classNames(
                    'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                    'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
                  )}
                  activeClassName={'border-pink-500 text-gray-900'}
                  exact={false}
                >
                  TikTok
                </NavLink>
              </nav>
            </div>
          </div>
        </div>

        <Switch>
          <Route path={`${match.path}/instagram`}>
            <InfluencerDetailInstagram data={data} />
          </Route>
          <Route path={`${match.path}/tiktok`}>
            <InfluencerDetailTikTok data={data} />
          </Route>
          <Route path={`${match.path}`}>
            <InfluencerDetailOverview data={data} />
          </Route>
        </Switch>
      </article>
      <SedCardNew
        config={{
          influencerId: influencerId,
          fields: [
            {
              type: 'group',
              caption: 'Basis Info',
              foldable: true,
              foldableOpened: true,
              fields: [
                {
                  name: 'name',
                  type: 'text',
                  label: 'Name',
                  required: true,
                  overrideData: (data.first_name) ? data.first_name + ' ' + data.last_name : undefined,
                  archiveData: (data.last_sedcard) ? data.last_sedcard.name : undefined,
                },
                {
                  name: 'tags',
                  type: 'text',
                  label: 'Tags',
                  required: true,
                  overrideData: (data.tags && data.tags.length > 0) ? data.tags.map(item => item.tag).join(' | ') : undefined,
                  archiveData: (data.last_sedcard) ? data.last_sedcard.tags : undefined,
                },
                {
                  name: 'shortbio',
                  type: 'longtext',
                  label: 'Short Bio',
                  required: true,
                  archiveData: (data.last_sedcard) ? data.last_sedcard.shortbio : undefined,
                },
                {
                  name: 'brandfit',
                  type: 'longtext',
                  label: 'Brandfit',
                  required: true,
                  archiveData: (data.last_sedcard) ? data.last_sedcard.brandfit : undefined,
                },
              ]
            },
            {
              type: 'group',
              caption: 'Instagram',
              foldable: true,
              foldableOpened: false,
              fields: [
                {
                  name: 'instagram',
                  type: 'text',
                  label: 'Name',
                  overrideData: (data.instagram) ? '@' + data.instagram : undefined,
                  archiveData: (data.last_sedcard) ? data.last_sedcard.instagram : undefined,
                },
                {
                  name: 'instagram_row1',
                  type: 'text',
                  label: 'Zeile 1',
                  overrideData: (data.instagram_follower) ? (Math.round(data.instagram_follower / 100000, 0) / 10.0).toLocaleString() + ' Mio. Follower' : undefined,
                  archiveData: (data.last_sedcard) ? data.last_sedcard.instagram_row1 : undefined,
                },
                {
                  name: 'instagram_row2',
                  type: 'text',
                  label: 'Zeile 2',
                  archiveData: (data.last_sedcard) ? data.last_sedcard.instagram_row2 : undefined,
                },
                {
                  name: 'instagram_row3',
                  type: 'text',
                  label: 'Zeile 3',
                  overrideData: (data.instagram_engagement_rate_posts) ? (Math.round(parseFloat(data.instagram_engagement_rate_posts) * 10.0, 0) / 10.0).toLocaleString() + ' % ER' : undefined,
                  archiveData: (data.last_sedcard) ? data.last_sedcard.instagram_row3 : undefined,
                },
              ]
            },
            {
              type: 'group',
              caption: 'Youtube',
              foldable: true,
              foldableOpened: false,
              fields: [
                {
                  name: 'youtube',
                  type: 'text',
                  label: 'Name',
                  archiveData: (data.last_sedcard) ? data.last_sedcard.youtube : undefined,
                },
                {
                  name: 'youtube_row1',
                  type: 'text',
                  label: 'Zeile 1',
                  archiveData: (data.last_sedcard) ? data.last_sedcard.youtube_row1 : undefined,
                },
                {
                  name: 'youtube_row2',
                  type: 'text',
                  label: 'Zeile 2',
                  archiveData: (data.last_sedcard) ? data.last_sedcard.youtube_row2 : undefined,
                },
                {
                  name: 'youtube_row3',
                  type: 'text',
                  label: 'Zeile 3',
                  archiveData: (data.last_sedcard) ? data.last_sedcard.youtube_row3 : undefined,
                },
              ]
            },
            {
              type: 'group',
              caption: 'Twitch',
              foldable: true,
              foldableOpened: false,
              fields: [
                {
                  name: 'twitch',
                  type: 'text',
                  label: 'Name',
                  archiveData: (data.last_sedcard) ? data.last_sedcard.twitch : undefined,
                },
                {
                  name: 'twitch_row1',
                  type: 'text',
                  label: 'Zeile 1',
                  archiveData: (data.last_sedcard) ? data.last_sedcard.twitch_row1 : undefined,
                },
                {
                  name: 'twitch_row2',
                  type: 'text',
                  label: 'Zeile 2',
                  archiveData: (data.last_sedcard) ? data.last_sedcard.twitch_row2 : undefined,
                },
                {
                  name: 'twitch_row3',
                  type: 'text',
                  label: 'Zeile 3',
                  archiveData: (data.last_sedcard) ? data.last_sedcard.twitch_row3 : undefined,
                },
              ]
            },
            {
              type: 'group',
              caption: 'TikTok',
              foldable: true,
              foldableOpened: false,
              fields: [
                {
                  name: 'tiktok',
                  type: 'text',
                  label: 'Name',
                  archiveData: (data.last_sedcard) ? data.last_sedcard.tiktok : undefined,
                },
                {
                  name: 'tiktok_row1',
                  type: 'text',
                  label: 'Zeile 1',
                  archiveData: (data.last_sedcard) ? data.last_sedcard.tiktok_row1 : undefined,
                },
                {
                  name: 'tiktok_row2',
                  type: 'text',
                  label: 'Zeile 2',
                  archiveData: (data.last_sedcard) ? data.last_sedcard.tiktok_row2 : undefined,
                },
                {
                  name: 'tiktok_row3',
                  type: 'text',
                  label: 'Zeile 3',
                  archiveData: (data.last_sedcard) ? data.last_sedcard.tiktok_row3 : undefined,
                },
              ]
            },
            {
              type: 'group',
              caption: 'Splits',
              foldable: true,
              foldableOpened: false,
              fields: [
                { label: 'Age Split (%)', type: 'text', name: 'age_split', archiveData: (data.last_sedcard) ? data.last_sedcard.age_split : undefined, },
                { label: 'Age Split Text', type: 'text', name: 'age_split_subline', archiveData: (data.last_sedcard) ? data.last_sedcard.age_split_subline : undefined, },
                { label: 'Country Split (%)', type: 'text', name: 'country_split', archiveData: (data.last_sedcard) ? data.last_sedcard.country_split : undefined, },
                { label: 'Country Split Text', type: 'text', name: 'country_split_subline', archiveData: (data.last_sedcard) ? data.last_sedcard.country_split_subline : undefined, },
                { label: 'City Split (%)', type: 'text', name: 'city_split', archiveData: (data.last_sedcard) ? data.last_sedcard.city_split : undefined, },
                { label: 'City Split Text', type: 'text', name: 'city_split_subline', archiveData: (data.last_sedcard) ? data.last_sedcard.city_split_subline : undefined, },
                {
                  label: 'Gender Split (%)',
                  type: 'number',
                  name: 'gender_split',
                  overrideData: (data.instagram_gender_split) ? parseFloat(data.instagram_gender_split) * 100.0 : undefined,
                  archiveData: (data.last_sedcard) ? data.last_sedcard.gender_split : undefined,
                },
                {
                  label: 'Gender Split Text',
                  type: 'text',
                  name: 'gender_split_subline',
                  overrideData: (data.instagram_gender_split) ? '♀' : undefined,
                  archiveData: (data.last_sedcard) ? data.last_sedcard.gender_split_subline : undefined,
                },
              ]
            },
            {
              type: 'group',
              caption: 'Bilder',
              foldable: true,
              foldableOpened: false,
              fields: [
                {
                  label: 'Hintergrund',
                  type: 'imagebrowser',
                  name: 'image1',
                  overrideData: 'random',
                  archiveData: (data.last_sedcard) ? data.last_sedcard.image1 : undefined,
                },
                {
                  label: 'Farbüberblendung',
                  type: 'select',
                  name: 'imageOpacity',
                  overrideData: (data.imageOpacity) ? parseFloat(data.imageOpacity) : 0.5,
                  archiveData: (data.last_sedcard) ? parseFloat(data.last_sedcard.imageOpacity) : 0.5,
                  options: [
                    {
                      val: 0.25,
                      name: '25% (viel rot)',
                    },
                    {
                      val: 0.5,
                      name: '50% (normal)',
                    },
                    {
                      val: 0.75,
                      name: '75% (wenig rot)',
                    }
                  ],
                },
                {
                  label: 'Vordergrund',
                  type: 'imagebrowser',
                  name: 'image2',
                  overrideData: 'random',
                  archiveData: (data.last_sedcard) ? data.last_sedcard.image2 : undefined,
                }
              ]
            }
          ],
          saveBtnLabel: 'Erstellen',
        }}
        isOpened={createNewSedCard}
        closeHandler={() => {
          setCreateNewSedCard(() => false);
        }}
        saveHandler={(event, values, files) => {
          event.preventDefault();
          setCreateNewSedCardLoading(() => true);
          //setModalUpload(() => true)

          /*let formData = undefined;
          if (files && files.file) {
            formData = new FormData();

            // Update the formData object
            formData.append(
              "file",
              files.file,
              files.file.name
            );
          }
          const reader = new FileReader();
          reader.readAsBinaryString(files.file);
          reader.onload = function() {
            setPreviewImage("data:image/png;base64, " + btoa(reader.result))
          };*/
          let formData = new FormData();
          _.forOwn(values, function(value, key) {
            formData[key] = value;
          });

          window.api.post('/api/influencer/' + influencerId + '/sedcard/', values)
            .then(function(response) {
              setCreateNewSedCardLoading(() => false);
              if (response.data) {
                window.open(window.api.defaults.baseURL + "sedcard/?ids=" + response.data.id, "_blank")
              }
            })
            .catch(function (error) {
              console.log(error)
            });

          if (formData) {
            console.log(formData)
          }
        }}
      />
    </>
  );
}

export default InfluencerDetail;
