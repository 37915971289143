import {Link} from "react-router-dom";
import React from "react";

function InfluencerDetailTikTok(props) {

  const data = props.data;

  return (
    <div className="flex mt-6 max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
      <h1>TikTok</h1>
    </div>
  );
}

export default InfluencerDetailTikTok;
